// PrivateRoute.jsx
import { UserContext } from './UserContext';
import { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PasswordProtectedPage from '../pages/PasswordProtectedPage';

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, userData } = useContext(UserContext);
  const location = useLocation();
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);

  const isAdminPanelRoute = [
    '/send/panel', '/char/panel', '/user/panel', '/shop/panel',
    '/shop/add', '/shop/view/log', '/news/panel', '/news/add',
    '/recharge/panel', '/recharge/add', '/recharge/logs', '/iplog/panel',
    '/download/panel', '/download/add', '/admin/panel', '/admin/add',
    '/admin/add/ticket', '/admin/log', '/admin/user/log', '/coupon/panel',
    '/coupon/add', '/coupon/log'
  ];

  const dynamicPathRegex = /^\/shop\/\d+|\/news\/\d+$/;
  const isAdminPanel = isAdminPanelRoute.includes(location.pathname) || dynamicPathRegex.test(location.pathname);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (isAdminPanel && (userData?.usertype !== 30 && userData?.usertype !== 20)) {
    return <Navigate to="/not-authorized" />;
  }

  const passwordProtectedPaths = [
    '/send/panel', '/char/panel', '/user/panel', '/shop/panel',
    '/shop/add', '/shop/view/log', '/news/panel', '/news/add',
    '/recharge/panel', '/recharge/add', '/recharge/logs', '/iplog/panel',
    '/download/panel', '/download/add', '/admin/panel', '/admin/add',
    '/admin/add/ticket', '/admin/log', '/admin/user/log', '/coupon/panel',
    '/coupon/add', '/coupon/log'
  ];
  const requiresPassword = passwordProtectedPaths.includes(location.pathname);

  if (requiresPassword && !isPasswordVerified) {
    return <PasswordProtectedPage onPasswordVerify={setIsPasswordVerified} />;
  }

  return children;
};

export default PrivateRoute;
