import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheckCircle, faTimesCircle, faUserShield, faUser } from '@fortawesome/free-solid-svg-icons';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Styled components (reuse from UserDetailsTable)
const Container = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows tables to stack on smaller screens */
`;

const Table = styled.table`
  padding: 15px;
  border-collapse: collapse;
  margin: 20px 20px; /* Adjust the margins here */
  width: 40%; /* Adjust width for two tables */
  background: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Table shadow */
  transition: all 0.3s ease;
`;

const TableRow = styled.tr`

  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Row hover effect */
    cursor: pointer;
  }
`;

const TableHeader = styled.th`
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 12px;
  text-align: left;
  font-weight: 600;
`;

const TableData = styled.td`
  padding: 15px;
  border-bottom: 1px solid #ddd;
  color: white;
  text-align: left;
  font-size: 16px;
  &:first-child {
    font-weight: bold;
    color: white;
    background-color: #2c3e50;
    width: 20%; /* Set a fixed width for the first column */
  }
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

const UpdateButton = styled(Button)`
  background-color: #3498db;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #2980b9;
  }
`;

const GlowText = styled.span`
  color: white;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7); /* Text glow effect */
`;

// Media Query for Responsiveness
const ResponsiveTable = styled.div`
  @media (max-width: 768px) {
    ${Table} {
      width: 100%;
    }

    ${TableData}, ${TableHeader} {
      font-size: 14px;
      padding: 10px;
    }

    ${Button}, ${UpdateButton} {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
`;

export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

// Character Card
const Card = styled.div`
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  width: 50%; /* Adjust width as needed */
`;

const CharacterList = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap */
  gap: 20px; /* Space between cards */
`;

const CharacterCard = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 15px;
  width: calc(33.33% - 50px); /* Three cards per row with space */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: white;
  display: flex; /* Enable flexbox */
  align-items: center; /* Center align items vertically */
  
  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
  }
`;

const BackButton = styled(Button)`
  margin: 20px 20px;
  background-color: #c9302c;
  &:hover {
    background-color: #e74c3c;
  }
`;

const RedText = styled.span`
    color: #c9302c;
`;

const CharacterImage = styled.img`
  width: 60px; /* Set a width for the character image */
  height: 60px; /* Set a height for the character image */
  border-radius: 50%; /* Optional: Make the image circular */
  margin-right: 15px; /* Space between image and text */
`;

// UserPanelEdit component
const UserPanelEdit = () => {
  const location = useLocation();
  const { id } = useParams();
  const { rankItem } = location.state || {}; // Ensure state is defined
  const navigate = useNavigate();

  const [characters, setCharacters] = useState([]);

  useEffect(() => {
    // Fetch character list data from API
    const fetchCharacters = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/usercharinfo?username=${rankItem.username}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
        });
        const data = await response.json();
        setCharacters(data.userchar); // Assuming API returns an object with a 'characters' array
      } catch (error) {
        console.error('Error fetching characters:', error);
      }
    };

    fetchCharacters();
  }, [rankItem.username]);


  return (
    <>
    <ResponsiveTable>
        <BackButton onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
        Back
      </BackButton>
      <Container>
        <Table>
          <tbody>
            <TableRow>
              <TableData>Customer name</TableData>
              <TableData>{rankItem?.CompleteName || 'No Data'}</TableData>
            </TableRow>
            <TableRow>
              <TableData>ID</TableData>
              <TableData>
                <GlowText>{rankItem?.username || 'No Data'}</GlowText>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>R-coin</TableData>
              <TableData>
                <GlowText><FaRegistered className="fas fa-registered" style={{marginRight:'10px'}} />{rankItem?.kbpoint.toLocaleString() || 'No Data'}</GlowText>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>Created</TableData>
              <TableData>
                <GlowText>{rankItem?.created_at || 'No Data'}</GlowText>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>Type</TableData>
              <TableData>
                <Button status={rankItem?.usertype === 30 ? 'admin' : 'player'}>
                  <FontAwesomeIcon icon={rankItem?.usertype === 30 ? faUserShield : faUser} />
                  {rankItem?.usertype === 30 ? 'Admin' : 'Player'}
                </Button>
              </TableData>
            </TableRow>
          </tbody>
        </Table>

        {/* Second Table */}
        <Table>
          <tbody>
            <TableRow>
              <TableData>Birth Date</TableData>
              <TableData>{rankItem?.birthdate || 'No Data'}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Email</TableData>
              <TableData>{rankItem?.useremail || 'No Data'}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Afiliator</TableData>
              <TableData>{rankItem?.userafiliator || 'No Data'}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Updated</TableData>
              <TableData>{rankItem?.updated_at || 'No Data'}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Status</TableData>
              <TableData><Button status={rankItem?.useravailable === 1 ? 'active' : 'inactive'}>
                {rankItem?.useravailable === 1 ? 'Active' : 'Inactive'}
              </Button></TableData>
            </TableRow>
          </tbody>
        </Table>
      </Container>
    </ResponsiveTable>

    {/* Character List Card */}
    <Card>
        <h2 style={{ color: 'white' }}>Character List</h2>
        <CharacterList>
            {characters?.length > 0 ? (
                characters.map((character, index) => (
                <CharacterCard key={index}>
                    <CharacterImage src={`../images/class/${character.chaclass}.png`} style={{height: "70px" }} alt={`${character.chaname}`} /> {/* Assuming character.chaimage holds the image filename */}
                    <div>
                    <h3>
                        Name: <RedText>{character.chaname}</RedText>
                    </h3>
                    <p>
                        Campus: 
                        <img src={`../images/campus/${character.chaschool}.png`} alt="class icon" style={{ width: '15px', height: '15px', marginLeft: '5px' }} />
                    </p>
                    <p>
                        Level: <RedText>{character.chalevel}</RedText>
                    </p>
                    <p>
                        Money: <RedText>{character.chamoney.toLocaleString()}</RedText>
                    </p>
                    {/* Add more character details as needed */}
                    </div>
                </CharacterCard>
                ))
            ) : (
                <p style={{ color: 'white' }}>No characters found.</p>
            )}
            </CharacterList>
      </Card>

    </>
  );
};

export default UserPanelEdit;
