import styled from 'styled-components';

const FooterWrapper = styled.footer`
  padding: 1rem 2rem;
  background: #333;
  color: white;
  text-align: center;
  border-top: 1px solid #444;
`;

const Footer = () => (
  <FooterWrapper>
    <p>&copy; 2024 © Ran Online: GS. All rights reserved.</p>
  </FooterWrapper>
);

export default Footer;
