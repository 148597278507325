import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Retrieve user data from localStorage
  const [userData, setUserData] = useState(() => {
    try {
      const storedData = localStorage.getItem('userData');
      return storedData ? JSON.parse(storedData) : null;
    } catch (error) {
      console.error('Error parsing user data from localStorage:', error);
      return null;
    }
  });

  // Check if user is logged in based on the existence of userData
  const isLoggedIn = !!userData;

  // Sync userData with localStorage whenever it changes
  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
    } else {
      localStorage.removeItem('userData');
    }
  }, [userData]);

  // Function to log out the user
  const logout = () => {
    setUserData(null);
  };

  return (
    <UserContext.Provider value={{ userData, setUserData, isLoggedIn, logout }}>
      {children}
    </UserContext.Provider>
  );
};
