// PasswordProtectedPage.jsx
import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const PasswordBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background-color: #2c3e50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #34495e;
  }
`;

const Message = styled.p`
  color: red;
  margin-top: 1rem;
`;

const PasswordProtectedPage = ({ onPasswordVerify }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const correctPassword = process.env.REACT_APP_PASSWORD_PROTECTED

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = () => {
    if (password === correctPassword) {
      onPasswordVerify(true);
      setError('');
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <Container>
      <PasswordBox>
        <h2>Password Protected Page</h2>
        <Input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button onClick={handleLogin}>Submit</Button>
        {error && <Message>{error}</Message>}
      </PasswordBox>
    </Container>
  );
};

export default PasswordProtectedPage;
