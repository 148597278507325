import React, { useState } from 'react';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';

// Styled components for Dropdown
const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 96%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background: #333;
  color: white;
`;

const DropdownList = styled(List)`
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow: auto;
  background: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
  margin: 0;
  padding: 0;
`;

const DropdownItem = styled.div`
  padding-left: 10px;
  padding-top: 10px;
  cursor: pointer;
  background: ;
  color: white;
  
  &:hover {
    background-color: black;
  }
`;

const AdminDropdownTextbox = ({ usernames, onUsernameSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const filteredUsernames = usernames.filter((username) => 
    username.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsDropdownOpen(true);
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    setIsDropdownOpen(false);
    onUsernameSelect(option); // Call the passed function
  };

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 100); // Delay to allow option click to register
  };

  return (
    <DropdownContainer>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder="Type or select a username"
      />
      {isDropdownOpen && filteredUsernames.length > 0 && (
        <DropdownList
          height={150} // Set the height of the dropdown
          itemCount={filteredUsernames.length}
          itemSize={40} // Set the height of each item
          width="100%"
        >
          {({ index, style }) => (
            <DropdownItem
              key={filteredUsernames[index]}
              style={style} // Apply the style to ensure correct positioning
              onClick={() => handleOptionClick(filteredUsernames[index])}
            >
              {filteredUsernames[index]}
            </DropdownItem>
          )}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default AdminDropdownTextbox;
