import gdrive from '../images/gdrive.png';
import mediafire from '../images/mediafire.png';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { faTrash,faSpinner,faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
  LabelPrimaryStyled,
  LabelDangerStyled,
  LabelSuccessStyled,
} from '../utils/LabelUtils';

// Styled components
const DownloadPageWrapper = styled.div`
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: transparent;
  min-height: 100vh;
`;

const DownloadTitle = styled.h1`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 40px;
`;

const DownloadsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 100px;
`;

const DownloadCard = styled.a`
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  height: 250px; /* Adjusted height if needed */
  text-align: center;
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-5px);
  }
`;

const DownloadIcon = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
`;

const DownloadCardTitle = styled.h2`
  font-size: 1.5rem;
  color: white;
  margin-bottom: 10px;
`;

const DownloadIconStyled = styled.i`
  font-size: 2rem;
  color: white;
  margin-top: 10px;
`;

const Download = () => {
  
  const [downloadList, setDownloadList] = useState([]);

  const fetchDownloadList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/downloadlist`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setDownloadList(data.downloadlist);
    } catch (error) {
      console.error('Error fetching player ranking:', error);
    }
  };

  // Simulate API call to fetch rankings
  useEffect(() => {
   
    fetchDownloadList();
  }, []);

  return (
    <DownloadPageWrapper>
      <DownloadTitle>Download Links</DownloadTitle>
      <DownloadsContainer>
        {downloadList.map((download, index) => (
          download.status === 1 && ( // Remove curly braces here
            <DownloadCard
              key={index}
              href={download.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadIcon src={`../${download.server}.png`} style={{ height: '45px', width: '50px' }} alt={download.server} />
              <DownloadCardTitle>{download.name}</DownloadCardTitle>
              <DownloadIconStyled className="fas fa-download"></DownloadIconStyled> {/* Font Awesome Download Icon */}
            </DownloadCard>
          )
        ))}
      </DownloadsContainer>
    </DownloadPageWrapper>
  );
};

export default Download;



// import gdrive from '../images/gdrive.png';
// import mediafire from '../images/mediafire.png';
// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';

// import { faTrash,faSpinner,faPlusCircle } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useNavigate } from 'react-router-dom';
// import {
//   LabelPrimaryStyled,
//   LabelDangerStyled,
//   LabelSuccessStyled,
// } from '../utils/LabelUtils';

// // Styled components
// const DownloadPageWrapper = styled.div`
//   text-align: center;
//   padding: 20px;
//   font-family: Arial, sans-serif;
//   background-color: transparent;
//   min-height: 100vh;
// `;

// const DownloadTitle = styled.h1`
//   font-size: 2.5rem;
//   color: white;
//   margin-bottom: 40px;
// `;

// const DownloadsContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   gap: 20px;
//   margin-top: 100px;
// `;

// const DownloadCard = styled.a`
//   background-color: rgba(0, 0, 0, 0.7);
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   padding: 20px;
//   width: 250px;
//   height: 250px; /* Adjusted height if needed */
//   text-align: center;
//   transition: transform 0.3s ease;
//   text-decoration: none;
//   color: inherit;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     transform: translateY(-5px);
//   }
// `;

// const DownloadIcon = styled.img`
//   width: 100px;
//   height: 100px;
//   object-fit: contain;
//   margin-bottom: 10px;
// `;

// const DownloadCardTitle = styled.h2`
//   font-size: 1.5rem;
//   color: white;
//   margin-bottom: 10px;
// `;

// const DownloadIconStyled = styled.i`
//   font-size: 2rem;
//   color: white;
//   margin-top: 10px;
// `;

// const Download = () => {
  
//   const [downloadList, setDownloadList] = useState([]);
//   const [autoDownloadLink, setAutoDownloadLink] = useState('');


//   const fetchDownloadList = async () => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/downloadlist`, {
//         headers: {
//           'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
//         },
//       });
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       setDownloadList(data.downloadlist);
//     } catch (error) {
//       console.error('Error fetching player ranking:', error);
//     }
//   };

//   const AutoDownload = ({ downloadLink }) => {
//     useEffect(() => {
//       const link = document.createElement('a');
//       link.href = downloadLink; // Dynamic Google Drive direct download link
//       link.setAttribute('download', '');
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }, [downloadLink]);
  
//     return (
//       <div>
//         <h2>Your download should start automatically.</h2>
//       </div>
//     );
//   };

//   const getDownloadLink = (link, server) => {
//     if (server.toLowerCase() === 'gdrive') {
//       const fileId = link.split('/d/')[1].split('/')[0]; // Extract Google Drive file ID
//       return `https://drive.google.com/uc?export=download&id=${fileId}`; // Return auto-download link
//     }
//     return link; // For other servers like Mediafire, just return the original link
//   };
  

//   // Simulate API call to fetch rankings
//   useEffect(() => {
//     fetchDownloadList();
//   }, []);

//   return (
//     <DownloadPageWrapper>
//       <DownloadTitle>BTS Ran Game Downloads</DownloadTitle>
//       {autoDownloadLink && <AutoDownload downloadLink={autoDownloadLink} />}
//       <DownloadsContainer>
//         {downloadList.map((download, index) => (
//           download.status === 1 && ( // Remove curly braces here
//             <DownloadCard
//               key={index}
//               href={getDownloadLink(download.link, download.server)}
//               target="_blank"
//               rel="noopener noreferrer"
//               onClick={() => download.server === 'GDrive' && setAutoDownloadLink(getDownloadLink(download.link, download.server))}
//             >
//               <DownloadIcon src={`../${download.server}.png`} style={{ height: '45px', width: '50px' }} alt={download.server} />
//               <DownloadCardTitle>{download.name}</DownloadCardTitle>
//               <DownloadIconStyled className="fas fa-download"></DownloadIconStyled> {/* Font Awesome Download Icon */}
//             </DownloadCard>
//           )
//         ))}
//       </DownloadsContainer>
//     </DownloadPageWrapper>
//   );
// };

// export default Download;
