// src/components/StatusWidget.js
import React from 'react';
import styled from 'styled-components';
import { FaPowerOff } from 'react-icons/fa';

const WidgetContainer = styled.div`
  background: #171717;
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  max-width: 300px;  // Reduce width
  margin: 1rem auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);  // Reduce hover effect
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);  // Adjust shadow
  }
  z-index: 1;
`;

const WidgetTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;  // Reduce bottom margin
  font-size: 1.4rem;  // Decrease font size
  text-transform: uppercase;
  letter-spacing: 0.8px;  // Decrease letter spacing
  font-weight: 600;
`;

const StatusText = styled.span`
  color: white;
  font-size: 1rem;  // Adjust font size
`;

const OnlineText = styled.span`
  color: ${(props) => (props.$isYellow ? 'yellow' : '#4cd137')};
  margin-left: 5px;
  font-size: 1rem;  // Adjust font size
`;

const StatusIcon = styled(FaPowerOff)`
  color: ${(props) => (props.$isYellow ? 'yellow' : '#4cd137')};
  margin-left: 10px;  // Reduce margin
  margin-right: 5px;  // Reduce margin
  font-size: 1.2rem;  // Decrease icon size
  transition: color 0.3s ease;

  ${WidgetContainer}:hover & {
    color: ${(props) => (props.$isYellow ? 'yellow' : '#4cd137')};
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;  // Adjust font size
`;

const OnlineCount = styled.span`
  font-size: 2rem;  // Decrease font size
  font-weight: bold;
  color: #4cd137;
  transition: color 0.3s ease;

  ${WidgetContainer}:hover & {
    color: #4cd137;
  }
`;

const PlayerOnline = styled.span`
  color: #ecf0f1;
  font-size: 1rem;  // Adjust font size
  margin-top: 0.25rem;  // Reduce top margin
`;

const StatusWidget = ({ onlinePlayers }) => {
  const isOnlineYellow = onlinePlayers > 150; // Check if onlinePlayers is greater than 150

  return (
    <WidgetContainer>
      <WidgetTitle>
        <StatusText>Status:</StatusText>
        <StatusIcon $isYellow={isOnlineYellow} /> {/* Pass the condition to StatusIcon */}
        <OnlineText $isYellow={isOnlineYellow}>Online</OnlineText> {/* Pass the condition to OnlineText */}
      </WidgetTitle>
      <StatusContainer>
        {/* <OnlineCount>{onlinePlayers}</OnlineCount> */}
        {/* <PlayerOnline>Players Online</PlayerOnline> */}
      </StatusContainer>
    </WidgetContainer>
  );
};

export default StatusWidget;
