export const getCategoryName = (category) => {
    const categoryName = category === 1 ? "R-Coin + Item"
                     : category === 2 ? "R-coin"
                     : category === 3 ? "Item"
                     : null;
    return categoryName;
  };

  export const getType = (category) => {
    const categoryName = category === 1 ? "Once Time"
                     : category === 2 ? "All Player"
                     : null;
    return categoryName;
  };