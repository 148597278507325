export const getDownloadServerName = (category) => {
    const categoryName = category === 'GDrive' ? "Google Drive"
                     : category === 'Mediafire' ? "Mediafire"
                     : category === 'MEGA' ? "Mega"
                     : null;
    return categoryName;
  };

  export const getDownloadCategoryName = (category) => {
    const categoryName = category === 'Client' ? "Client"
                     : category === 'Patch' ? "Patch"
                     : null;
    return categoryName;
  };