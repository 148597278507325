import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    LabelPrimaryStyled,
    LabelDangerStyled,
    LabelSuccessStyled,
    LabelWarningStyled
  } from '../utils/LabelUtils';
import { faPlusCircle,faTrash, faEdit, faUserShield,faClipboardList  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center align content now that there's only one section */
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1; /* Adjusted to fill available space */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 20px; /* Increase padding for larger buttons */
  margin: 0 8px; /* Adjust margin if needed */
  background-color: ${(props) => (props.active ? '#2c3e50' : '#bdc3c7')};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px; /* Increase font size if needed */

  &:hover {
    background-color: #2c3e50;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7f8c8d;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #27ae60; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #2ecc71; /* Lighter green on hover */
  }
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

const UpdateButton = styled(Button)`
  background-color: #c9302c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #e74c3c;
  }
`;



const DeleteButton = styled(Button)`
  background-color: #c9302c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #c0392b;
  }
`;

const RechargeLogButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #c9302c; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #e74c3c; /* Lighter green on hover */
  }
`;


const handleDelete = async(id,fetchRankings) => {

  const confirmation = window.confirm(
    `Do you want to delete coupon id : ${id}?`
  );

  if (confirmation) {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/coupon/delete?id=${id}`, {
          method: 'DELETE', // Specify the DELETE method
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
      });

      if (response.ok) {
          fetchRankings();
          alert('Coupon has been successfully removed.');
      } else {
          const errorData = await response.json(); // Get the error response
          throw new Error(`Delete failed: ${errorData.error}`);
      }
    } catch (error) {
        console.error('Error deleting download:', error);
    }
  }
};

// Main component
const CouponPanelList = () => {
  const [coupon, setCoupon] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const navigate = useNavigate();


  const fetchRankings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/couponpanel`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCoupon(data.couponpanel);
    } catch (error) {
      console.error('Error fetching player ranking:', error);
    }
  };


  // Simulate API call to fetch rankings
  useEffect(() => {
    fetchRankings();
  }, []);

  // Filter rankings based on the search term
  const filteredCoupon = coupon
    .filter((rankItem) =>
      rankItem.code.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Get current page rankings
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCoupon = filteredCoupon.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredCoupon.length / itemsPerPage);

  const getPaginationItems = () => {
    const pageNumbers = [];
    const visiblePages = 3; // Number of pages to show before and after the current page
  
    if (totalPages <= 5) {
      // If total pages are 5 or fewer, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show the first page
      pageNumbers.push(1);
  
      // Show ellipsis if current page is far from the beginning
      if (currentPage > visiblePages + 2) {
        pageNumbers.push('ellipsis-left');
      }
  
      // Show pages around the current page
      for (let i = Math.max(2, currentPage - visiblePages); i <= Math.min(totalPages - 1, currentPage + visiblePages); i++) {
        pageNumbers.push(i);
      }
  
      // Show ellipsis if current page is far from the end
      if (currentPage < totalPages - visiblePages - 1) {
        pageNumbers.push('ellipsis-right');
      }
  
      // Always show the last page
      pageNumbers.push(totalPages);
    }
  
    // Ensure ellipses are not repeated
    return pageNumbers.filter((item, index, self) =>
      index === self.indexOf(item) && !(item === 'ellipsis-left' && self[index - 1] === 'ellipsis-left') &&
      !(item === 'ellipsis-right' && self[index + 1] === 'ellipsis-right')
    );
  };

   // Handle add item button click
   const handleAddCoupon = () => {
    navigate('/coupon/add'); // Navigate to add item page (change URL as needed)
   };

   // Handle add item button click
  const handleCouponLog = () => {
    navigate('/coupon/log'); // Navigate to add item page (change URL as needed)
  };


  return (
    <PageContainer>
      <ContentWrapper>
        {/* Ranking Table Section */}
        <RankingContainer>
        
          <Title>Coupon Panel - List</Title>

          <AddButton onClick={handleAddCoupon}>
                <FontAwesomeIcon icon={faPlusCircle} />
                Create Coupon
        </AddButton>

        <RechargeLogButton onClick={handleCouponLog}>
            <FontAwesomeIcon icon={faClipboardList} />
            Coupon Log
        </RechargeLogButton>

        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '20px' }}></div>
          <SearchInput
            type="text"
            placeholder="Search by code..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>Type</TableHeader>
                <TableHeader>Category</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Code</TableHeader>
                <TableHeader>R-coin</TableHeader>
                <TableHeader>Product Num</TableHeader>
                <TableHeader>Date</TableHeader>
                <TableHeader>Used</TableHeader>
                <TableHeader>Action</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentCoupon.map((rankItem, index) => (
                <TableComponent
                  key={rankItem.id}
                  rankItem={rankItem}
                  fetchRankings={fetchRankings}
                />
              ))}
            </tbody>
          </RankingTable>

          {/* Pagination */}
          <Pagination>
            <PageButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </PageButton>

            {getPaginationItems().map((pageNumber, index) => {
              if (pageNumber === 'ellipsis-left' || pageNumber === 'ellipsis-right') {
                return <Ellipsis key={index}>...</Ellipsis>;
              }

              return (
                <PageButton
                  key={pageNumber}
                  active={currentPage === pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </PageButton>
              );
            })}

            <PageButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </PageButton>
          </Pagination>
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

const TableComponent = ({ rankItem, fetchRankings}) => {
    // Define your base path for images
    const campusBasePath = '../images/campus/'; // Adjust the path according to your folder structure
  
    // Generate the complete image path
    const imageCampusPath = `${campusBasePath}nocamp.png`;

    const handleUpdateStatus = async (id, currentStatus) => {
      const statusName = currentStatus === 1 ? 'Hidden' : 'Active';
      const newStatus = currentStatus === 1 ? 0 : 1; // Toggle the status
    
      const confirmation = window.confirm(
        `The current status is ${statusName}. Do you want to ${currentStatus === 1 ? 'activate' : 'hide'} this item?`
      );
    
      if (confirmation) {
        const updatedNews = { status: newStatus };
    
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/coupon/status/update/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
            },
            body: JSON.stringify(updatedNews),
          });
    
          if (!response.ok) {
            throw new Error('Failed to update coupon status');
          }
    
          fetchRankings(prevNewsData =>
            prevNewsData.map(news =>
              news.id === id ? { ...news, status: newStatus } : news
            )
          );
          alert('Status updated successfully');
        } catch (error) {
          console.error('Error updating news:', error);
        }
      }
    };
  
    return (
        <TableRow>
            <TableData>
            {rankItem.type === 1 ? (
                <LabelPrimaryStyled>Once Time</LabelPrimaryStyled>
            ) : <LabelWarningStyled>All Player</LabelWarningStyled>}
            </TableData>
            <TableData>
            {rankItem.category === 3 ? (
                <LabelDangerStyled>Item</LabelDangerStyled>
            ) : rankItem.category === 2 ? (
                <LabelPrimaryStyled>R-coin</LabelPrimaryStyled>
            ) : <LabelSuccessStyled>R-coin + Item</LabelSuccessStyled>}
            </TableData>
            <TableData>
            {rankItem.status === 1 ? (
                <LabelDangerStyled>Non-Active</LabelDangerStyled>
            ) : <LabelSuccessStyled>Active</LabelSuccessStyled>}
            <UpdateButton style={{ marginLeft: '10px' }} onClick={() => handleUpdateStatus(rankItem.id,rankItem.status)}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </UpdateButton>
            </TableData>
            <TableData>{rankItem.code}</TableData>
            <TableData>{rankItem.kbpoint.toLocaleString()}</TableData>
            <TableData>{rankItem.productnum}</TableData>
            <TableData>{formatDate(rankItem.created_at)}</TableData>
            <TableData>{rankItem.used}</TableData>
            <TableData>
                <DeleteButton onClick={() => handleDelete(rankItem.id,fetchRankings)}>
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </DeleteButton>
            </TableData>
      </TableRow>
    );
  };

  const formatDate = (dateString) => {
    // Create a Date object from the UTC date string
    const date = new Date(dateString);
  
    // Get the year, month, and day in UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    // Get the hours, minutes, and seconds in UTC
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Return in YYYY-MM-DD HH:MM:SS format
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

export default CouponPanelList;
