import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';

// Styled components for Dropdown
const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 96%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background: #333;
  color: white;
`;

const DropdownList = styled(List)`
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow: auto;
  background: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
  margin: 0;
  padding: 0;
`;

const DropdownItem = styled.div`
  padding-left: 10px;
  padding-top: 10px;
  cursor: pointer;
  color: white;
  
  &:hover {
    background-color: black;
  }
`;

// Searchable Dropdown Component
const CouponDropdownTextbox = ({ items, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const filteredItems = items.filter(item => {
    // Convert both reference and searchTerm to strings and to lower case
    const referenceString = String(item.reference).toLowerCase();
    const searchString = searchTerm.toLowerCase();
  
    return referenceString.includes(searchString);
  });

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setShowDropdown(true);
  };

  const handleSelectItem = (item) => {
    onSelect(item);
    setSearchTerm(item.reference);
    setShowDropdown(false);
  };

  // Handle clicks outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const Row = ({ index, style }) => (
    <DropdownItem 
      style={style} 
      key={filteredItems[index].reference} 
      onClick={() => handleSelectItem(filteredItems[index])}
    >
      {filteredItems[index].reference}
    </DropdownItem>
  );

  return (
    <DropdownContainer ref={dropdownRef}>
      <Input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={() => setShowDropdown(true)}
        placeholder="Type or select a reference code"
      />
      {showDropdown && filteredItems.length > 0 && (
        <DropdownList
          height={150} // Set the fixed height for the dropdown list
          itemCount={filteredItems.length}
          itemSize={35} // Set the height for each item in the list
          width={'100%'}
        >
          {Row}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default CouponDropdownTextbox;
