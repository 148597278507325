import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import PayPalButton from './PayPalComponent';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Import the UserContext


// Container for the form section
export const Container = styled.div`
  width: 30%;
`;

// Form section styling
export const FormSection = styled.div`
  margin-bottom: 20px;
`;

export const CartTotalsTable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  border-collapse: collapse;

  td {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: top;
    white-space: nowrap; /* Prevent text from wrapping */
  }

  th {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: top;
    white-space: nowrap; /* Prevent text from wrapping in headers too */
  }
`;

// Table headers and data
export const TableHeader = styled.th`
  text-align: left;
  font-weight: bold;
`;

export const TableData = styled.td`
  text-align: left;
`;

// Form input styling
export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

// Disabled input styling
export const DisabledInput = styled(Input)`
  background-color: #f9f9f9;
  cursor: not-allowed;
  width: 90%;
`;

// Button styling
export const SubmitButton = styled.button`
 width: 100%;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #3498db; /* Blue background */
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9; /* Darker blue on hover */
  }
`;

// Alignment for the buttons
export const ButtonWrapper = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Space between buttons */
`;

// Flexbox container for the form sections
const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

// Individual column for form sections
const FormColumn = styled.div`
  flex: 1;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

// Cancel button styling
export const CancelButton = styled.button`
  display: inline-block;
  padding: 10px 30px;
  font-size: 16px;
  color: white;
  background-color: red; /* Blue background */
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b; /* Darker blue on hover */
  }
`;

const CheckoutForm = ({id,kbpoint,price,logo,currencySign}) => {
  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate(); // Initialize useNavigate
  const [orderCount, setOrderCount] = useState(null);

  const handleProceedToInvoice = async () => {
    try {
      // First, register the transaction
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/transactlog`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
        body: JSON.stringify({
          id: userData.usernum,
          username: userData.username,
          payment_type: "kbx",
          payment_method: "Peso",
          transaction_status: "pending",
          kbpoint: kbpoint,
          point_status: "wait.gif",
          referralname: "no-ref",
          afiliator: "NO-AFIL",
          price: price,
          pricetot: price,
          reward: 1,
          sumprice: 0,
          proofimage: "",
          reference: "",
          timeproof: "",
          kbpoint_num: kbpoint,
          staff_name: "",
        }),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();

      const { orderId } = result;
  
      // Navigate to the invoice page after successful transaction registration
      navigate('/invoice', { state: { orderId, id, kbpoint, price, logo, currencySign } });
  
    } catch (err) {
      console.error("Error registering transaction:", err);
    }
  };

    return (
      <Container>
        <FormContainer>
          <FormColumn>
            <h3 className="nk-title h4">Cart Totals</h3>
            <FormSection>
              <CartTotalsTable>
                <tbody>
                  <tr>
                    <TableHeader>Unique Code</TableHeader>
                    <TableData>{id}</TableData>
                  </tr>
                  <tr>
                    <TableHeader>Total</TableHeader>
                    <TableData>
                  <span
                    style={{
                      marginRight: '10px'  // Adjust the value as needed
                    }}
                  >
                    {currencySign}
                  </span>
                  <strong style={{ fontSize: '24px', color:'red' }}>{price.toLocaleString()}</strong>
                </TableData>
                  </tr>
                </tbody>
              </CartTotalsTable>
            </FormSection>
          </FormColumn>
          <FormColumn>
            <h3 className="nk-title h4">Payment Details</h3>
            <form action="/wise/pay" method="POST" className="third-contact-form">
              <div className="youplay-input">
                <DisabledInput
                  type="text"
                  name="referral"
                  id="referral"
                  placeholder="Referral Code"
                  disabled
                />
              </div>
              <br />
              <ButtonWrapper>
                  <CancelButton type="button" onClick={() => window.history.back()}>
                      Cancel
                  </CancelButton>
                  {/* <SubmitButton type="submit">Proceed to Checkout</SubmitButton> */}
                  {currencySign === '$' ? (
                    <PayPalButton
                      amount={price} 
                      kbpoint={kbpoint} 
                      userid={userData.usernum} 
                      username={userData.username}
                    />
                  ) : (
                    <SubmitButton type="button" onClick={handleProceedToInvoice}>
                      Proceed to Checkout
                    </SubmitButton>
                  )}
              </ButtonWrapper>
            </form>
          </FormColumn>
        </FormContainer>
      </Container>
    );
};

export default CheckoutForm;
