import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/ran-logo.png';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { UserContext } from './UserContext'; // Import the UserContext

// Styled components for Navbar and Login Dropdown
const NavbarWrapper = styled.nav`
  display: grid;
  grid-template-columns: auto 1fr auto; /* Added auto column for login dropdown */
  align-items: center;
  padding: 0 2rem;
  height: 10%;
  background: #171717;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
`;

const Logo = styled.img`
  height: 110px;
  width: auto;
  padding-right: 2rem;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: 2rem;
`;

const NavLinkStyled = styled(NavLink)`
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background 0.3s ease;

  &.active {
    background: #273c75;
  }

  &:hover {
    background: #273c75;
  }
`;

const Dropdown = styled.div`
  position: relative;
`;

const DropdownToggle = styled.div`
  cursor: pointer;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background: #273c75;
  }
`;

const ArrowIcon = styled.div`
  font-size: 0.75rem;
  transition: transform 0.3s ease;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: #1c1c1c; /* Darker background */
  color: #eee; /* Lighter text color */
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 5px 5px 0 rgba(0, 0, 0, 0.3); /* Adjusted shadow */
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  z-index: 1000;
  width: 200px;
  margin-top: 0.5rem;
  padding: 1rem;
`;

const DropdownItem = styled(NavLink)`
  display: block;
  padding: 0.5rem 1rem;
  color: #eee; /* Lighter text color */
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease;

  &.active, &:hover {
    background: #273c75;
    color: white;
  }
`;

const LoginDropdown = styled(Dropdown)`
  margin-left: auto; /* Push the login dropdown to the rightmost side */
`;

const LoginDropdownToggle = styled(DropdownToggle)`
  margin-left: auto; /* Push the login dropdown to the rightmost side */
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LoginInput = styled.input`
  width: 90%; /* Full width of container */
  padding: 15px;
  font-size: 12px;
  border: 1px solid #444; /* Dark border for input */
  border-radius: 4px; /* Rounded corners */
  background: #333; /* Darker background for input */
  color: #eee; /* Lighter text color for input */
  
  &.error {
    border-color: #ff6f61; /* Red border for errors */
  }
`;

const ErrorSpan = styled.span`
  font-size: 14px;
  color: #ff6f61; /* Red color for error messages */
  margin: 5px 0; /* Margin for spacing between field and error */
`;

const SubmitButton = styled.button`
  margin-top: 10px;
  background: #273c75; /* Teal background for button */
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px; /* Rounded corners */
  font-family: "Roboto", sans-serif;

  &:disabled {
    cursor: not-allowed;
    background: #004d40; /* Darker teal for disabled state */
  }
  width: 100%; /* Full width of container */
`;

const FormFieldContainer = styled.div`
  width: 93%; /* Full width of container */
  margin-bottom: 20px; /* Space below each field */
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  transition: background 0.3s ease;

  &:hover {
    background: #273c75;
  }
`;

const UserProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const UserIcon = styled.div`
  font-size: 24px;
  margin-right: 10px;
`;

const UserName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const ProfileDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: #1c1c1c;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 5px 5px 0 rgba(0, 0, 0, 0.3); /* Adjusted shadow */
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  margin-top: 0.5rem; /* Add margin-top to match other dropdowns */
  padding: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width:200px;
`;

const Button = styled.button`
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }
  justify-content: space-between;
`;

const LogoutButton = styled(Button)`
  background: #dc3545;

  &:hover {
    background: #c82333;
  }
`;

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const [values, setValues] = useState({ userId: '', password: '' });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef({});
  const loginDropdownRef = useRef(null);
  const { userData, setUserData } = useContext(UserContext);

  const isActive = (path) => location.pathname === path;

  const menuItems = [
    { path: '/', label: 'Home', exact: true },
    { path: '/news', label: 'News' },
    { path: '/download', label: 'Download' },
    isLoggedIn && { path: '/shop', label: 'Shop' },
    { 
      label: 'Ranking', 
      dropdown: [
        { path: '/ranking/player', label: 'Player Ranking' },
        { path: '/ranking/guild', label: 'Guild Ranking' },
        { path: '/ranking/rich', label: 'Rich Ranking' },
        { path: '/ranking/contribution', label: 'Contribution Ranking' },
      ] 
    },
    isLoggedIn && { 
      label: 'Recharge',
      dropdown: [
        { path: '/recharge', label: 'Recharge' },
        { path: '/recharge/log', label: 'Recharge Log' },
      ] 
    },
    isLoggedIn && (userData?.usertype === 30 || userData?.usertype === 20) && { 
      label: 'Admin',
      dropdown: [
        userData?.superadmin === 1 && { path: '/admin/panel', label: 'Super Admin Panel' }, // Super Admin Panel is optional
        { path: '/user/panel', label: 'User Panel' },
        { path: '/char/panel', label: 'Char Panel' },
        { path: '/shop/panel', label: 'Shop Panel' },
        userData?.superadmin === 1 && { path: '/send/panel', label: 'Send Panel' },
        { path: '/news/panel', label: 'News Panel' },
        { path: '/iplog/panel', label: 'IP Log Panel' },
        { path: '/download/panel', label: 'Download Panel' },
        { path: '/recharge/panel', label: 'Recharge Panel' },
        { path: '/coupon/panel', label: 'Coupon Panel' },
      ].filter(Boolean) // Filter out falsy values from the dropdown
    },
    !isLoggedIn && { path: '/terms', label: 'Join Now' },
  ].filter(Boolean); // Filter out falsy values in the entire menu

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const storedUserData = localStorage.getItem('userData');
    const expirationTime = localStorage.getItem('logoutExpiration');
  
    if (token && expirationTime) {
      const currentTime = Date.now();
      if (currentTime > expirationTime) {
        handleLogout(); // Auto-logout if the expiration time has passed
      } else {
        setIsLoggedIn(true); // Set login state if the token and valid expiration time exist
        setUserData(JSON.parse(storedUserData)); // Load user data from localStorage
  
        // Recalculate the remaining time and set a new timer
        const remainingTime = expirationTime - currentTime;
        const logoutTimer = setTimeout(() => {
          handleLogout();
        }, remainingTime);
  
        localStorage.setItem('logoutTimer', logoutTimer);
      }
    }
  }, []);

  const handleDropdownToggle = (label) => {
    setDropdownOpen(prev => (prev === label ? null : label));
  };

  const handleLoginDropdownToggle = () => {
    setLoginDropdownOpen(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (Object.values(dropdownRef.current).every(ref => ref && !ref.contains(event.target)) &&
        !loginDropdownRef.current?.contains(event.target)) {
      setDropdownOpen(null);
      setLoginDropdownOpen(false);
    }
  };

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setValues((values) => ({
      ...values,
      [name]: value
    }));
  }, []);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    
    const validationErrors = validateForm(values);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setValid(false);
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
        body: JSON.stringify({
          userId: values.userId,
          password: values.password,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('userData', JSON.stringify(data.user));
        setUserData(data.user);
        setValid(true);
        setSubmitted(true);
        setIsLoggedIn(true); // Set the login state to true
        navigate('/');
  
        // Calculate the expiration time (1 hour in the future)
        const expirationTime = Date.now() + 30 * 60 * 1000; // 1 hour in milliseconds
        localStorage.setItem('logoutExpiration', expirationTime);
  
        // Optionally, store the logout timer ID
        const logoutTimer = setTimeout(() => {
          handleLogout();
        }, 30 * 60 * 1000); // 1 hour in milliseconds
  
        localStorage.setItem('logoutTimer', logoutTimer);
      } else {
        setErrors({ login: data.error });
        setValid(false);
      }
    } catch (error) {
      setErrors({ login: 'Something went wrong. Please try again later.' });
    }
  };
  

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove the token on logout
    localStorage.removeItem('userData');
    localStorage.removeItem('logoutExpiration'); // Clear expiration time
    localStorage.removeItem('logoutTimer'); // Clear the stored timer ID
    setIsLoggedIn(false); // Reset login state
    setUserData(null);
    setLoginDropdownOpen(false); // Close the dropdown
    setValues({ userId: '', password: '' }); // Clear login form
    setErrors({});
    window.location.reload();
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.userId) errors.userId = "Please enter a user ID";
    if (!values.password) errors.password = "Please enter a password";
    return errors;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <NavbarWrapper>
      <Link to="/">
        <Logo src={logo} alt="GameSite Logo" />
      </Link>
      <NavLinks>
        {menuItems.map(item => (
          item.dropdown ? (
            <Dropdown key={item.label} ref={el => dropdownRef.current[item.label] = el}>
              <DropdownToggle onClick={() => handleDropdownToggle(item.label)}>
                {item.label}
                <ArrowIcon $isOpen={dropdownOpen === item.label}>
                  {dropdownOpen === item.label ? <FaCaretUp /> : <FaCaretDown />}
                </ArrowIcon>
              </DropdownToggle>
              <DropdownMenu $isOpen={dropdownOpen === item.label}>
                {item.dropdown.map(dropdownItem => (
                  <DropdownItem
                    key={dropdownItem.path}
                    to={dropdownItem.path}
                    onClick={() => {
                      setDropdownOpen(null);
                      navigate(dropdownItem.path);
                    }}
                    className={location.pathname === dropdownItem.path ? 'active' : ''}
                  >
                    {dropdownItem.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          ) : (
            <NavLinkStyled
              key={item.path}
              to={item.path}
              end={item.exact}
            >
              {item.label}
            </NavLinkStyled>
          )
        ))}
      </NavLinks>
      <LoginDropdown ref={loginDropdownRef}>
        {isLoggedIn ? (
          <LoginDropdownToggle onClick={handleLoginDropdownToggle}>
            <UserProfileContainer>
              <UserIcon>👤</UserIcon>
              {userData && (
                <UserName>{userData.CompleteName}</UserName>
            )}
            </UserProfileContainer>
            <ArrowIcon $isOpen={loginDropdownOpen}>
              {loginDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
            </ArrowIcon>
          </LoginDropdownToggle>
        ) : (
          <LoginDropdownToggle onClick={handleLoginDropdownToggle}>
            Login
            <ArrowIcon $isOpen={loginDropdownOpen}>
              {loginDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
            </ArrowIcon>
          </LoginDropdownToggle>
        )}
		  {!isLoggedIn && (
          <DropdownMenu $isOpen={loginDropdownOpen}>
            <LoginForm onSubmit={handleLoginSubmit}>
              {!valid && (
                <>
                  <FormFieldContainer>
                    <LoginInput
                      type="text"
                      placeholder="User ID"
                      name="userId"
                      value={values.userId}
                      onChange={handleInputChange}
                      className={errors.userId ? "error" : ""}
                    />
                    {errors.userId && (
                      <ErrorSpan id="user-id-error">{errors.userId}</ErrorSpan>
                    )}
                  </FormFieldContainer>
                  <FormFieldContainer>
                    <LoginInput
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleInputChange}
                      className={errors.password ? "error" : ""}
                    />
                    {errors.password && (
                      <ErrorSpan id="password-error">{errors.password}</ErrorSpan>
                    )}
                  </FormFieldContainer>
                  {errors.login && (
                    <ErrorSpan id="login-error">{errors.login}</ErrorSpan>
                  )}
                  <SubmitButton type="submit" disabled={valid}>
                    Login
                  </SubmitButton>
                </>
              )}
            </LoginForm>
          </DropdownMenu>
        )}
        {isLoggedIn && (
          <ProfileDropdown $isOpen={loginDropdownOpen}>
            <ButtonContainer>
              <Button onClick={() => navigate('/profile')}>View Profile</Button>
              {/* <Button onClick={() => navigate('/profile/edit')}>Update</Button> */}
              {/* <Button onClick={() => navigate('/profile/edit/password')}>Update password</Button> */}
              <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </ButtonContainer>
          </ProfileDropdown>
        )}
      </LoginDropdown>
    </NavbarWrapper>
  );
};

export default Navbar;