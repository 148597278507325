import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { faEdit,faSpinner,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
  LabelPrimaryStyled,
  LabelDangerStyled,
  LabelSuccessStyled,
} from '../utils/LabelUtils';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center align content now that there's only one section */
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1; /* Adjusted to fill available space */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 20px; /* Increase padding for larger buttons */
  margin: 0 8px; /* Adjust margin if needed */
  background-color: ${(props) => (props.active ? '#2c3e50' : '#bdc3c7')};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px; /* Increase font size if needed */

  &:hover {
    background-color: #2c3e50;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7f8c8d;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const LoadingSpinner = styled(FontAwesomeIcon)`
  font-size: 48px;
  color: #2c3e50;
  animation: spin 1s infinite linear;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const BackButton = styled.button`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

// Main component
const SuperAdminPanelLog = () => {
  const [adminList, setAdminList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  // Simulate API call to fetch rankings
  useEffect(() => {
    const fetchUserList = async () => {
      setLoading(true); 
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/adminloglist`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAdminList(data.adminloglist);
      } catch (error) {
        console.error('Error fetching player ranking:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchUserList();
  }, []);

  // Filter rankings based on the search term
  const filteredAdminList = adminList
    .filter((userName) =>
        userName.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Get current page rankings
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAdminList= filteredAdminList.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredAdminList.length / itemsPerPage);

  const getPaginationItems = () => {
    const pageNumbers = [];
    const visiblePages = 3; // Number of pages to show before and after the current page
  
    if (totalPages <= 5) {
      // If total pages are 5 or fewer, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show the first page
      pageNumbers.push(1);
  
      // Show ellipsis if current page is far from the beginning
      if (currentPage > visiblePages + 2) {
        pageNumbers.push('ellipsis-left');
      }
  
      // Show pages around the current page
      for (let i = Math.max(2, currentPage - visiblePages); i <= Math.min(totalPages - 1, currentPage + visiblePages); i++) {
        pageNumbers.push(i);
      }
  
      // Show ellipsis if current page is far from the end
      if (currentPage < totalPages - visiblePages - 1) {
        pageNumbers.push('ellipsis-right');
      }
  
      // Always show the last page
      pageNumbers.push(totalPages);
    }
  
    // Ensure ellipses are not repeated
    return pageNumbers.filter((item, index, self) =>
      index === self.indexOf(item) && !(item === 'ellipsis-left' && self[index - 1] === 'ellipsis-left') &&
      !(item === 'ellipsis-right' && self[index + 1] === 'ellipsis-right')
    );
  };

  return (
    <PageContainer>
      <ContentWrapper>
        {/* Ranking Table Section */}
        <RankingContainer>
            <BackButton onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
            Back
          </BackButton>
          <Title>Admin Panel - Log</Title>
          {loading ? (
            <LoadingContainer>
              <LoadingSpinner icon={faSpinner} />
            </LoadingContainer>
          ) : ( <>
          <SearchInput
            type="text"
            placeholder="Search by user name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>Admin</TableHeader>
                <TableHeader>User</TableHeader>
                <TableHeader>Type</TableHeader>
                <TableHeader>OldData</TableHeader>
                <TableHeader>NewData</TableHeader>
                <TableHeader>Time</TableHeader>
              </tr>
            </thead>
            <tbody>
            {currentAdminList.map((rankItem,index) => (
              <TableComponent
                key={index} // Ensure a unique key
                rankItem={rankItem} // Pass the entire rankItem object
              />
            ))}
            </tbody>
          </RankingTable>

          {/* Pagination */}
          <Pagination>
            <PageButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </PageButton>

            {getPaginationItems().map((pageNumber, index) => {
              if (pageNumber === 'ellipsis-left' || pageNumber === 'ellipsis-right') {
                return <Ellipsis key={index}>...</Ellipsis>;
              }

              return (
                <PageButton
                  key={pageNumber}
                  active={currentPage === pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </PageButton>
              );
            })}

            <PageButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </PageButton>
          </Pagination>
          </>)}
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

const UpdateButton = styled(Button)`
  background-color: #c9302c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #c0392b;
  }
`;

const TableComponent = ({ rankItem  }) => {
  const navigate = useNavigate();

    return (
        <TableRow>
            <TableData>{rankItem.adminname}</TableData>
            <TableData>{rankItem.username}</TableData>
            <TableData>{rankItem.type}</TableData>
            <TableData>{rankItem.olddata}</TableData>
            <TableData>{rankItem.newdata}</TableData>
            <TableData>{formatDate(rankItem.created_at)}</TableData>
      </TableRow>
    );
  };

  const formatDate = (dateString) => {
    // Create a Date object from the UTC date string
    const date = new Date(dateString);
  
    // Get the year, month, and day in UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    // Get the hours, minutes, and seconds in UTC
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Return in YYYY-MM-DD HH:MM:SS format
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

export default SuperAdminPanelLog;
