import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { faTrash, faEdit,faPlusCircle,faShoppingBag  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
  LabelPrimaryStyled,
  LabelDangerStyled,
  LabelSuccessStyled,
  LabelWarningStyled,
} from '../utils/LabelUtils';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center align content now that there's only one section */
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1; /* Adjusted to fill available space */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const RankingTable = styled.table`
  width: 70%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableHeaderMonthly = styled.th`
  background-color: #000;
  color: white;
  padding: 10px;
  text-align: left;
  width: 10%;
  border: 1px dashed #ccc;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 20px; /* Increase padding for larger buttons */
  margin: 0 8px; /* Adjust margin if needed */
  background-color: ${(props) => (props.active ? '#2c3e50' : '#bdc3c7')};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px; /* Increase font size if needed */

  &:hover {
    background-color: #2c3e50;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7f8c8d;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

const Table1 = styled.table`
  padding: 15px;
  border-collapse: collapse;
  margin: 20px 20px; /* Adjust the margins here */
  width: 40%; /* Adjust width for two tables */
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Table shadow */
  transition: all 0.3s ease;
`;

const TableRow1 = styled.tr`

  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Row hover effect */
    cursor: pointer;
  }
`;

const TableHeaderGate = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
  width: 10%;
`;

const TableHeaderAction = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
  width: 10%;
`;

const TableData1 = styled.td`
  padding: 15px;
  
  border: 1px dashed #ddd;
  color: white;
  text-align: left;
  font-size: 16px;
  &:first-child {
    font-weight: bold;
    color: white;
    background-color: #2c3e50;
    width: 30%; /* Set a fixed width for the first column */
  }
`;

const TableDataEdit = styled.td`
  padding: 15px;
  
  border: 1px dashed #ddd;
  color: white;
  text-align: left;
  font-size: 16px;
  width: 10%;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #27ae60; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #2ecc71; /* Lighter green on hover */
  }
`;


const RechargeLogButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #c9302c; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #e74c3c; /* Lighter green on hover */
  }
`;


// Main component
const RechargePanelList = () => {
  const [pesoList, setPesoList] = useState([]);
  const [paypalList, setPaypalList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [monthlyTotal, setMonthlyTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [kbStatus, setKBStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [showModal, setShowModal] = useState(false); 
  const navigate = useNavigate();

  const fetchPesoList = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/voucherpeso`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPesoList(data.peso);
    } catch (error) {
        console.error('Error fetching player ranking:', error);
    }
    };

    const fetchPaypalList = async () => {
        try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/voucherpaypal`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPaypalList(data.paypal);
        } catch (error) {
        console.error('Error fetching player ranking:', error);
        }
    };

    const fetchKBStatus = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/kbstatus`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
            },
          });
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setKBStatus(data.kbstatus);
      } catch (error) {
          console.error('Error fetching player ranking:', error);
      }
    };

    const fetchTransactionMonthlyTotal = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/transaction/monthlytotal`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
            },
          });
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setMonthlyTotal(data.monthlytotal);
      } catch (error) {
          console.error('Error fetching player ranking:', error);
      }
    };
    

    useEffect(() => {
        fetchKBStatus();
        fetchPesoList();
        fetchPaypalList();
        fetchTransactionMonthlyTotal();
    }, []);

    // Handle add item button click
   const handleAddVoucher = () => {
    navigate('/recharge/add'); // Navigate to add item page (change URL as needed)
   };

   // Handle add item button click
  const handleRechargeLog = () => {
    navigate('/recharge/logs'); // Navigate to add item page (change URL as needed)
  };

  const findStatus = (gateway) => {
      const status = kbStatus.find(item => item.gateway === gateway);
      if (status) {
          return status.status === '1' ? <LabelSuccessStyled>Online</LabelSuccessStyled> : <LabelDangerStyled>Offline</LabelDangerStyled>;  // Check if status is 1 or 0
      }
      return 'No Data';  // Default case when no matching gateway is found
  };

  const findDetail = (gateway) => {
      const status = kbStatus.find(item => item.gateway === gateway);
      if (status) {
          return <><LabelPrimaryStyled>{status.status}</LabelPrimaryStyled> <LabelWarningStyled>{status.detail}</LabelWarningStyled></>
      }
      return 'No Data';  // Default case when no matching gateway is found
  };

  const handleEdit = async (selectedGateway) => {
    const findGateway = kbStatus.find(item => item.gateway === selectedGateway);
    const newStatus = findGateway.status === '1' ? '0' : '1'; // Toggle status
    const gatewayName = selectedGateway === 'wise' 
    ? 'Gcash Status' 
    : selectedGateway === 'paypal' 
    ? 'Paypal Status' 
    : selectedGateway === 'midtrans'
    ? 'Midtrans Status' 
    : selectedGateway === 'bankpeso'
    ? 'East Status' 
    : '';
      
    const confirmation = window.confirm(`Do you want to set the ${gatewayName} to ${newStatus === '1' ? 'online' : 'offline'}?`);

    if(confirmation){
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/kbstatus/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
          body: JSON.stringify({
            gateway: selectedGateway,
            status: newStatus,
          }),
        });
    
        if (response.ok) {
          fetchKBStatus();
          alert(`${gatewayName} has been set to ${newStatus === '1' ? 'online' : 'offline'} successfully.`);
        } else {
          const errorData = await response.json();
          alert(errorData.error || 'Error updating status');
        }
      } catch (error) {
        console.error('Error updating transaction:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
      <PageContainer>
    <ContentWrapper>
      {/* Ranking Table Section */}
      <RankingContainer>
      <Title>Recharge Panel - List</Title>
      
      <AddButton onClick={handleAddVoucher}>
            <FontAwesomeIcon icon={faPlusCircle} />
            Create Voucher
      </AddButton>

      <RechargeLogButton onClick={handleRechargeLog}>
            <FontAwesomeIcon icon={faShoppingBag} />
            Recharge Log
        </RechargeLogButton>

      <center>
          <Table1>
              <tbody>
              <TableRow1>
                  <TableData1>Gcash Status</TableData1>
                  <TableData1>{findStatus('wise')}</TableData1>
                  <TableData1>
                  <UpdateButton onClick={() => handleEdit('wise')}>
                      <FontAwesomeIcon icon={faEdit} />
                      Edit
                  </UpdateButton>
                  </TableData1>
              </TableRow1>
              <TableRow1>
                  <TableData1>East Status</TableData1>
                  <TableData1>{findStatus('bankpeso')}</TableData1>
                  <TableData1>
                  <UpdateButton onClick={() => handleEdit('bankpeso')}>
                      <FontAwesomeIcon icon={faEdit} />
                      Edit
                  </UpdateButton>
                  </TableData1>
              </TableRow1>
              <TableRow1>
                  <TableData1>Midtrans Status</TableData1>
                  <TableData1>{findStatus('midtrans')}</TableData1>
                  <TableData1>
                  <UpdateButton onClick={() => handleEdit('midtrans')}>
                      <FontAwesomeIcon icon={faEdit} />
                      Edit
                  </UpdateButton>
                  </TableData1>
              </TableRow1>
              <TableRow1>
                  <TableData1>Paypal Status</TableData1>
                  <TableData1>{findStatus('paypal')}</TableData1>
                  <TableData1>
                  <UpdateButton onClick={() => handleEdit('paypal')}>
                      <FontAwesomeIcon icon={faEdit} />
                      Edit
                  </UpdateButton>
                  </TableData1>
              </TableRow1>
              <TableRow1>
                  <TableData1>Gcash account</TableData1>
                  <TableData1>{findDetail('gcashacc')}</TableData1>
                  <TableData1>
                  <UpdateButton onClick={() => alert('test')}>
                      <FontAwesomeIcon icon={faEdit} />
                      Edit
                  </UpdateButton>
                  </TableData1>
              </TableRow1>
              <TableRow1>
                  <TableData1>East account</TableData1>
                  <TableData1>{findDetail('bankacc')}</TableData1>
                  <TableData1>
                  <UpdateButton onClick={() => alert('test')}>
                      <FontAwesomeIcon icon={faEdit} />
                      Edit
                  </UpdateButton>
                  </TableData1>
              </TableRow1>
              </tbody>
          </Table1>

          <thead>
            <tr>
            <TableHeaderMonthly>GCASH: <LabelSuccessStyled>₱       {monthlyTotal?.peso_pricetot?.toLocaleString() || '0'}</LabelSuccessStyled></TableHeaderMonthly>
            <TableHeaderMonthly>EAST: <LabelSuccessStyled>₱      {monthlyTotal?.bankpeso_pricetot?.toLocaleString() || '0'}</LabelSuccessStyled></TableHeaderMonthly>
            <TableHeaderMonthly>PAYPAL: <LabelPrimaryStyled>$      {monthlyTotal?.pp_pricetot?.toLocaleString() || '0'}</LabelPrimaryStyled></TableHeaderMonthly>
            <TableHeaderMonthly>MIDTRANS: <LabelWarningStyled>Rp.    {monthlyTotal?.m_pricetot?.toLocaleString() || '0'}</LabelWarningStyled></TableHeaderMonthly>
            </tr>
        </thead>
  
      
      <tr style={{ height: '20px' }} /> {/* Adds space between rows */}
          
      {renderTableRows(pesoList)}
      <tr style={{ height: '50px' }} /> {/* Adds space between rows */}
      {renderTableRows(paypalList)}

        </center>
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

const renderTableRows = (pesoList) => {
    return <RankingTable>
    <thead>
        <tr>
        <TableHeaderGate>Gate</TableHeaderGate>
        <TableHeader>Name</TableHeader>
        <TableHeader>R-coin</TableHeader>
        <TableHeader>Price</TableHeader>
        <TableHeaderAction>Action</TableHeaderAction>
        </tr>
    </thead>
    <tbody>
    {pesoList.map((rankItem) => (
        <TableComponent
        key={rankItem.usernum} // Ensure a unique key
        rankItem={rankItem} // Pass the entire rankItem object
        />
    ))}
    </tbody>
    </RankingTable>
  };

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

const UpdateButton = styled(Button)`
  background-color: #c9302c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #c0392b;
  }
`;

const TableComponent = ({ rankItem  }) => {
    // Define your base path for images
    const campusBasePath = '../images/campus/'; // Adjust the path according to your folder structure
  
    // Generate the complete image path
    const imageCampusPath = `${campusBasePath}nocamp.png`;

    const paymentBasePath = '../images/payment/';
    const imagePaymentPath = `${paymentBasePath}${rankItem.payment_method.toLowerCase()}.png`;
  
    return (
        <TableRow>
            <TableData><img src={imagePaymentPath} alt="class icon" style={{ height: "20px" }} /></TableData>
            <TableData>{rankItem.itemname}</TableData>
            <TableData><FaRegistered className="fas fa-registered" style={{marginRight:'10px'}} />{rankItem.kbpoint.toLocaleString()}</TableData>
            
            <TableData>

            
            {rankItem.payment_method === 'PP' ? (
              <>$   {rankItem.price.toLocaleString()}</>
              ) : <>₱   {rankItem.price.toLocaleString()}</>
            }
            </TableData>
            
            <TableData>
              <UpdateButton onClick={() => alert('delete')}>
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </UpdateButton>
            </TableData>
      </TableRow>
    );
  };

export default RechargePanelList;