import { useState, useEffect, useContext } from 'react';
import React from 'react';
import styled from 'styled-components';
import ShopItemCard from '../components/ShopItemCard';


import { UserContext } from '../components/UserContext'; // Adjust the import as necessary

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%; /* Adjust the width */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  margin-right: 20px; /* Add margin between Container and SideContainer */
`;

const SideContainer = styled.div`
  width: 20%; /* Adjust width to fit beside the main container */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  margin-left: 20px; /* Add margin between SideContainer and Container */
  color: white;
  height: 10%;
`;

const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 30%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

const Shop = () => {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { userData } = useContext(UserContext);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shop`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setItems(data.shop); // Ensure it's an array
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []); // Empty dependency array ensures it runs only once

  const handleAddToCart = (item) => {
    console.log(`${item.title} added to cart!`);
  };

  const filteredItems = items.filter(item =>
    item.ItemName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  
  return (
    <MainContainer>
      <Container>
        <SearchInput
          type="text"
          placeholder="Search for items..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <PageContainer>
          {filteredItems.map((item, index) => (
            <ShopItemCard key={index} item={item} onAddToCart={handleAddToCart} />
          ))}
        </PageContainer>
      </Container>
      
      <SideContainer>
        <h2>R-coin :  <FaRegistered className="fas fa-registered" /> {userData?.kbpoint}</h2>
        {/* You can add any content you'd like in this side container */}
      </SideContainer>
    </MainContainer>
  );
};

export default Shop;