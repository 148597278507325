import React from 'react';
import AnnouncementCard from './AnnouncementCard';
import styled from 'styled-components';

// Styled component for the section wrapper
const SectionWrapper = styled.div`
  padding: 3rem 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default to 3 columns */
  gap: 1rem;
  background: rgba(0, 0, 0, 0.7);
  justify-items: center;

  /* Media query for lower resolution screens (e.g., tablets, smaller desktops) */
  @media (max-width: 1366px) {
    grid-template-columns: repeat(2, 1fr); /* Change to 2 columns */
  }

  /* Media query for very small screens (e.g., mobile devices) */
  @media (max-width: 600px) {
    grid-template-columns: 1fr; /* Change to 1 column */
  }
`;

const AnnouncementSection = ({ announcements = [] }) => (
  <SectionWrapper>
    {announcements.length > 0 ? (
      announcements.map((announcement) => (
        <AnnouncementCard
          key={announcement.id} //
          id={announcement.id} // Ensure each card has a unique key
          date={announcement.created_at}
          title={announcement.title}
          author={announcement.author}
          content={announcement.description}
        />
      ))
    ) : (
      <p>Loading announcements.</p> // Fallback content if news is empty or undefined
    )}
  </SectionWrapper>
);

export default AnnouncementSection;