// TermsOfServicePage.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 200px;
  padding-right: 200px;
  background-color: #f5f5f5;
  padding-bottom: 50px;
`;

const Title = styled.h1`
  margin-bottom: 20px;
`;

const Text = styled.p`
  margin-bottom: 20px;
`;

const CheckboxContainer = styled.div`
  margin-bottom: 20px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const ProceedButton = styled.button`
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #34495e;
  }
`;

const TermsOfServicePage = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handleProceed = () => {
    navigate('/register'); // Redirect to the registration page
  };

  return (
    <Container>
      <Title>Terms of Service</Title>
      <Text>
      <p>Our Terms of Service were last updated on 1 October 2024.</p>
        <p></p>
        <p><strong><u>TABLE OF CONTENTS</u></strong></p>
        <p><strong>ARTICLE 1</strong><br/><strong>SECTION I:</strong> INTRODUCTION<br/><strong>SECTION II:</strong> ORGANIZATION INFORMATION<br/><strong>SECTION III:</strong> CUSTOMER SUPPORT</p>
        <p><br/><strong>ARTICLE 2</strong><br/><strong>SECTION I:</strong> PUBLICATION<br/><strong>SECTION II:</strong> AMENDMENTS</p>
        <p><br/><strong>ARTICLE 3</strong><br/><strong>SECTION I:</strong> RULING RULES<br/><strong>SECTION II:</strong> OPERATIONAL POLICY</p>
        <p><br/><strong>ARTICLE 4</strong><br/><strong>SECTION I:</strong> METHOD OF USE<br/><strong>SECTION I:</strong> REGISTRATION<br/><strong>SECTION III:</strong> LIMITATION FOR REGISTRATION<br/><strong>SECTION IV:</strong> USE OF ACCOUNT BY MINORS<br/><strong>SECTION V:</strong> TERMINATION OF ACCOUNT</p>
        <p><br/><strong>ARTICLE 5</strong><br/><strong>SECTION I:</strong> PROTECTION AND MANAGEMENT OF PERSONAL INFORMATION.<br/><strong>SECTION II:</strong> COLLECTION OF INFORMATION</p>
        <p><br/><strong>ARTICLE 6</strong><br/><strong>SECTION I:</strong>RCOINS ACQUISITION AND USAGE POLICY<br/><strong>SECTION II:</strong> WITHDRAWING PAID CONTENTS AND RCOINS<br/><strong>SECTION III:</strong> DISCLAIMER OF WARRANTIES<br/><strong>SECTION IV:</strong>PURCHASING, REQUESTING AND WITHDRAWING PAID CONTENTS/RCOINS</p>
        <p><br/><strong>ARTICLE 7</strong><br/><strong>SECTION I:</strong> CODE OF CONDUCT<br/><strong>SECTION II:</strong> OBLIGATIONS OF THE ORGANIZATION<br/><strong>SECTION III:</strong> LIMITATIONS OF ORGANIZATION LIABILITY<br/><strong>SECTION IV:</strong> OBLIGATIONS OF PLAYERS<br/><strong>SECTION V:</strong> COMPENSATION FOR DAMAGE<br/><strong>SECTION VI:</strong>NOTICE TO USERS</p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p><strong>ARTICLE 1</strong><br/><strong>INTRODUCTION, ORGANIZATION INFORMATION, AND COMPANY SUPPORT</strong></p>
        <p></p>
        <p><strong>SECTION I. INTRODUCTION &ndash;</strong> This Terms of Service (hereinafter referred to as the "TOS", &ldquo;Terms&rdquo;, &ldquo;Agreement) is a legally binding agreement between QuickSilver Games, its subsidiaries, and/or affiliates (hereinafter referred to as &ldquo;QuickSilver&rdquo;, "Organization&rdquo;, &ldquo;we&rdquo;, "us", or "our") and you (commonly referred as &ldquo;your&rdquo;, &ldquo;they&rdquo;, &ldquo;player(s)&rdquo;, or &ldquo;user(s)&rdquo;). This Agreement sets forth the rights, duties, and obligations under which, it grants you access to utilize and enjoy our games, any game-specific websites, social media, application, customer support, in-game products and/or virtual items, and any additional services (hereinafter referred to as the QS Services&rdquo;) provided by QuickSilver.</p>
        <p><br/>Upon accessing, registering, and utilizing our QS Services, you explicitly acknowledge your consent to our TOS, Operational Policy, and any supplementary terms governing other QS Services. It is your responsibility to remain informed of any revisions to these documents. In agreeing to these Terms:</p>
        <ol>
        <li>
        <p>You also acknowledge that you are using the QS Services at your own risk and that our liability to you is subject to the limitations outlined in Article 7, Section 3 below; and<br/>ii. You expressly acknowledge and affirm that its provisions bind you. By accessing or using QS Services, you affirm that you have reviewed, comprehended, and accepted the rights, responsibilities, provisions, and conditions outlined within this Agreement, and you hereby consent to be legally bound by its terms;</p>
        </li>
        <li>
        <p>Your engagement with the Organization signifies your agreement to adhere to these TOS. Failure to comprehend or consent to these TOS, or inability to adhere to them, prohibits your access to QS Services. Continued usage of QS Services confirms your acceptance of these TOS, which may undergo periodic revisions. We, at this moment, assert the reserved right to amend, modify, or replace any provision within these Terms at our discretion. Moreover, upon confirmation, you certify that you are of legal age and possess the necessary legal capacity to engage in contractual agreements under the laws of your jurisdiction and the country from which you access our QS Services.</p>
        </li>
        </ol>
        <p>If you do not agree to any term of this Agreement, please refrain from using or accessing QS Services, either directly or indirectly, in any way.</p>
        <p></p>
        <p><strong>SECTION II. ORGANIZATION INFORMATION &ndash;</strong> The name of the Organization shall be QuickSilver Games with the business address at Golden Grain Villas, Mojon, Malolos, Bulacan, and the official website at https://ranonlinegs.com/.<br/><br/><br/><strong>SECTION III. CUSTOMER SUPPORT &ndash;</strong> The Organization provides official customer support through Discord (referred to as &ldquo;Customer Support&rdquo;), accessible at https://discord.gg/q9sMARmPbf. Our Customer Support will consist of a hundred percent (100%) manual or live support, manned by designated moderators, game masters, and administrators (referred to as &ldquo;Customer Support Team&rdquo;). The Customer Support Team will ensure all Users&rsquo; concerns, queries, complaints, and/or suggestions are promptly addressed and resolved, and will prioritize providing personalized assistance to ensure the satisfaction of all Users. By engaging with our Customer Support Team&rsquo;s services, you hereby acknowledge and agree that:</p>
        <ol>
        <li>
        <p>Not all feedback may lead to immediate changes;</p>
        </li>
        <li>
        <p>Time required for resolution shall be contingent upon the existing demand from individuals seeking assistance; and</p>
        </li>
        <li>
        <p>The expression of feedback, concerns, queries, complaints, and/or suggestions using derogatory language, or in a manner detrimental to the Organization, is strictly prohibited.</p>
        </li>
        </ol>
        <p><br/>The Customer Support Team will use the official discord server as its automated ticketing system.</p>
        <p></p>
        <p><strong>ARTICLE 2</strong><br/><strong>PUBLICATION AND AMENDMENTS</strong></p>
        <p>QuickSilver is committed to ensuring that the Terms of Service (referred to as &ldquo;TOS&rdquo; or &ldquo;Terms&rdquo;) are easily accessible and understandable to all players. To achieve the goal:</p>
        <p><br/><strong>SECTION I. PUBLICATION &ndash;</strong> The Terms of Service (referred to as &ldquo;TOS&rdquo; or &ldquo;Terms&rdquo;) are duly published on the official website of the Organization, accessible via the following link: https://ranonlinegs.com/. This concerted effort ensures access for all players to the comprehensive provisions outlined within the TOS. Aligned with the Organizational ethos of transparency, trust, and mutual understanding among its player base, the TOS is accurately written in clear and concise language to facilitate comprehension by individuals of varying backgrounds and capacities.</p>
        <p><br/>For users who have been utilizing QS Services prior to the formulation of the TOS, it is incumbent upon them to exercise due diligence by checking the TOS on the official website. The continued use of QS Services implies their explicit agreement to the outlined Terms.</p>
        <p><br/><br/><strong>SECTION II. AMENDMENTS &ndash;</strong> In the event of amendments to the Terms of Service, the Organization will announce the effective date, details, and reasons for the change. Notice shall be given on the official website of the Organization and/or the official Discord server. Significant changes to the TOS shall be communicated to players at least one (1) day (about 24 hours) before the effective date.</p>
        <p><br/>Failure to express explicit consent or rejection by the effective date may be construed as acceptance thereof. Should a player disagree with the amendment to the TOS, they shall forfeit access to the QS Services, effective immediately upon the implementation of the amendment. By continuing to access or use the QS Services following the implementation of any amendment to the TOS, players indicate their acceptance of such changes.</p>
        <p><strong>ARTICLE 3</strong><br/><strong>RULING RULES AND OPERATIONAL POLICY</strong></p>
        <p><strong>SECTION I. RULING RULES &ndash;</strong> In the event of any matters not explicitly addressed within these TOS, or in the interpretation thereof, such matters shall be governed by applicable laws and regulations.<br/><br/><strong>SECTION II. OPERATIONAL POLICY &ndash;</strong> To establish the necessary guidelines for the adoption of the TOS, protection of Players' rights and interests, and maintenance of order in the game world, the Organization retains the authority to institute the Operating Policy as outlined within the specified parameters of the TOS. The Organization is required to transparently inform Users of the specifics of the Operating Policy by publishing it on the official platforms such as the website and Discord server.<br/><br/>In the event of a significant revision to the Operating Policy that may substantially impact the rights and/or obligations of the Players or the Terms of Service, the procedure outlined in Article 2 (referred to as the "Publication and Amendments") shall govern. However, if the revision to the Operating Policy pertains to any of the subclauses below, such amendment shall be notified in advance via the official website and/or official Discord:</p>
        <ol>
        <li>
        <p>Amendments to provisions explicitly outlined within the purview of the Terms of Service;</p>
        </li>
        <li>
        <p>Amendments to provisions unrelated to the rights and obligations of players; and</p>
        </li>
        <li>
        <p>Changes to the content of the Operating Policy that do not deviate significantly from those articulated in the Terms of Service and are foreseeable by players.</p>
        </li>
        </ol>
        <p></p>
        <p><strong>ARTICLE 4</strong><br/><strong>METHOD OF USE, REGISTRATION, LIMITATIONS FOR REGISTRATION, AND USE OF ACCOUNT BY MINORS</strong></p>
        <p><strong>SECTION I. METHOD OF USE &ndash;</strong>To access and utilize the QS services offered by the Organization, the Users must agree and consent to the Terms and Service.</p>
        <p></p>
        <p><strong>SECTION II. REGISTRATION &ndash;</strong>You are required to register or create an account to use the QS service. You bear exclusive responsibility for all activities conducted through your account and for adherence to these Terms, encompassing, without limitation, all transactions made using the established account. You are prohibited from establishing or utilizing an account, or utilizing any QS Services, on behalf of any other individual or legal entity for commercial purposes.</p>
        <p><br/>You can register or create an account (referred to as &ldquo;Login Credentials&rdquo;) on our official website (https://ranonlinegs.com/register/). By creating an account with us, you must adhere and approve to the following conditions:</p>
        <p></p>
        <ol>
        <li>
        <p>The Login Credentials should be a unique combination selected at the player&rsquo;s convenience. It should contain letters, numbers, and special characters;</p>
        </li>
        <li>
        <p>You must be of legal age or have valid and legal guardian consent to be bound by these Agreements;</p>
        </li>
        <li>
        <p>You must provide a valid or up-to-date email address for your account(s) and other needed information about you whenever prompted or permitted by any site registration process (referred to as &ldquo;Personal data&rdquo;);</p>
        </li>
        <li>
        <p>You are prohibited from sharing your account or login credentials with anyone or offering to do so. Users are responsible for all actions performed, whether authorized by them or not, using or accessing the QS Services through their accounts;</p>
        </li>
        <li>
        <p>You must keep your login credentials secure and confidential, including but not limited to email addresses, passwords, or other related account information. You must be obliged to exercise due diligence in managing your account information;</p>
        </li>
        <li>
        <p>You are not entitled to claim compensation from QuickSilver. In the event of a scam, theft, hack, unauthorized use, or any other security breach related to your account, it is your responsibility to promptly notify us. You shall be held liable for any damage incurred because of your failure to properly manage your account;</p>
        </li>
        <li>
        <p>In the event of a scam, theft, hack, unauthorized use, or any other security breach related to your account, it is your responsibility to promptly notify us; or</p>
        </li>
        <li>
        <p>We may terminate your account at any time for any reason without any further formality if we have reason to believe that you have failed to comply with this Agreement.</p>
        </li>
        </ol>
        <p>Please promptly notify the Organization of any security breach, including unauthorized access to your account, or loss, theft, or unauthorized use or disclosure of your login credentials or payment information. We wish to reiterate that Users are accountable for all losses incurred on their account, including those related to QS service use, if they share their login credentials or fail to maintain their security.<br/><br/><strong>SECTION III. LIMITATION FOR REGISTRATION &ndash;</strong> QuickSilver hereby reserves the right to refuse acceptance and/or cancel a registration, even after initial acceptance, if it falls under any of the following conditions:</p>
        <ol>
        <li>
        <p>A User fails to accept or violates this Agreement, including but not limited to the community guidelines or the Article 3 Section II, Registration clauses above;</p>
        </li>
        <li>
        <p>A minor register without a guardian consent;</p>
        </li>
        <li>
        <p>If a User provides a false, stolen, inaccurate, or incomplete information during registration;</p>
        </li>
        <li>
        <p>If a User is found to have provided false or stolen information regarding their payment method or for any in-game purchases;</p>
        </li>
        <li>
        <p>The Organization is not capable of approving registration due to technical reason; and</p>
        </li>
        <li>
        <p>If a User has a history of hacking, scamming, or has been banned from any of the QS Services or game;</p>
        </li>
        </ol>
        <p></p>
        <p><strong>SECTION IV. USE OF ACCOUNT BY MINORS &ndash;</strong> Users may create or utilize an account or access QS Services only if over the age of sixteen (16) or legal age in your country of residence. All under the age of sixteen (16) will be ineligible to enter into this Agreement, even with parental or legal guardian consent. For Users aged 16 or older but under eighteen (18) (referred to as a &ldquo;Minor&rdquo;), they must jointly review and accept this Term with their parental or legal guardian. Acting as their legal guardian, they are accepting to be bound by this Agreement on behalf of the Minor. They acknowledge and agree that they will be held responsible for all actions undertaken by the Minor using the created account or any QS Services, regardless of whether such actions were authorized by them. QuickSilver disclaims liability for any consequences resulting from a Minor's registration without legal guardian consent.</p>
        <p><br/><strong>SECTION V. TERMINATION OF ACCOUNT &ndash;</strong> Users have the prerogative to deactivate their account at any given time by initiating contact with us via our official customer support group at https://discord.gg/q9sMARmPbf. QuickSilver retains the authority to terminate or suspend an account under the following circumstances:</p>
        <p></p>
        <ol>
        <li>
        <p>If User has contravened or breached any provision of this Agreement;</p>
        </li>
        <li>
        <p>If User has violated any specific Code of Conduct outlined in Article 7 Section 1;</p>
        </li>
        <li>
        <p>If it is determined that terminating the account would be in the best interest of the players of the Organization (hereinafter referred to as the "QS Community"); and</p>
        </li>
        <li>
        <p>The account represents a source of misconduct that has the potential to detrimentally impact both the Community and the Organization itself.</p>
        </li>
        </ol>
        <p></p>
        <p>Should there be any perceived errors or the need to report any individual, please feel free to contact us at our official customer support group at https://discord.gg/q9sMARmPbf, and we will ensure a thorough review of the situation.</p>
        <p><br/>In the event that QuickSilver terminates the user's account, access to the registered account and/or utilization of QS Services, including but not limited to any in-game products and/or virtual items purchased in connection with the Terminated Account, shall be forfeited without entitlement to any refund.<br/><br/>Users hereby acknowledge and agree that utilizing QS Services entails the risk of account termination or suspension in accordance with the terms outlined herein. It is the user's responsibility to acknowledge this risk and comply with the Code of Conduct detailed in Article 7, Section 1, as appropriate, when accessing QS Services.</p>
        <p></p>
        <p><strong>ARTICLE 5</strong><br/><strong>PROTECTION AND MANAGEMENT OF PERSONAL INFORMATION AND COLLECTION OF INFORMATION</strong></p>
        <p><strong>SECTION I. PROTECTION AND MANAGEMENT OF PERSONAL INFORMATION &ndash;</strong> At QuickSilver, we take data privacy seriously. All information gathered from all Users is handled in accordance with relevant laws, regulations, and our Privacy Policy. The Organization is committed to safeguarding your personal and confidential data with legal boundaries. Users must agree that this Agreement is subject to periodic review and revision by us, and by continuing to use QS Services, you implicitly consent to any updated Terms. We encourage you to stay informed about any changes accessible at our official website and our official support group server.</p>
        <p><br/>Users have the right to request alterations or removal of personal information after our review process. The organization, however, shall not be held liable for any inadvertent disclosure of personal information resulting from user negligence.</p>
        <p><br/><strong>SECTION II. COLLECTION OF INFORMATION &ndash;</strong>The Organization maintains and stores all communication within the game world, official website, and official support group server, including but not limited to chats between QuickSilver employees and users, chats between players, and in-game messages among players.</p>
        <ol>
        <li>
        <p>The Organization reserves the right to access and review this information under specific circumstances:</p>
        </li>
        <li>
        <p>For complaint processing, as we may review information on the complaint resolution process;</p>
        </li>
        <li>
        <p>Access is permitted for maintaining game order or maintenance; or</p>
        </li>
        <li>
        <p>For Dispute resolution, we may need to access information when necessary to settle disputes among Players.</p>
        </li>
        <li>
        <p>For account security, as when we investigate a case of account theft or unauthorized access;<br/>v. For financial-related transactions, as we need to address cash-related matters; or<br/>vi. For handling violations such as derogatory language, fraud, or bug abuse; and<br/>vii. For utilizing informaton of an in-game setting or all developmental processes to improve the quality and stabilization of the QS Service operation and program;<br/><br/></p>
        </li>
        </ol>
        <p>All users are hereby reminded that the QuickSilver team will never solicit your password. Users are expressly advised against disclosing their passwords, secret answers, or billing information to any party. Users bear full responsibility for all activities conducted under their account, and the Organization disclaims any liability to provide compensation under any circumstances.</p>
        <p></p>
        <p>Access to this information may be required for investigation, processing, confirmation, and remedies related to serious violations of the Terms outlined in Article 7 Section 1 of the TOS.</p>
        <p></p>
        <p>For violating the TOS or Operating Policy, the Player&rsquo;s family name, character name, etc. may be posted on the official website and other online platforms of the Organization.</p>
        <p></p>
        <p></p>
        <p><strong>ARTICLE 6</strong><br/><strong>RCOINS ACQUISITION AND USAGE POLICY, WITHDRAWING PAID CONTENTS AND RCOINS, WITHDRAWING PAID CONTENTS AND RCOINS, DISCLAIMER OF WARRANTIES, AND PURCHASING, REQUESTING AND WITHDRAWING PAID CONTENTS/RCOINS</strong></p>
        <p></p>
        <p><strong>SECTION I. RCOINS ACQUISITION AND USAGE POLICY &ndash;</strong> As part of the services provided by the Organization, players may purchase and utilize RCoins as an in-game currency (referred to as &ldquo;Game Currency&rdquo;). Game Currency may exclusively be acquired through the official website of the Organization, accessible at<a href="https://ranonlinegs.com/shop">https://ranonlinegs.com/shop</a> . This process, commonly referred to as "top-up", allows players to purchase Game Currency using the following accepted payment methods: GCash; Online bank payments; and PayPal (referred to as &ldquo;preferred method of payment&rdquo;).</p>
        <p><br/>Game Currency serves as the designated currency for transactions within the Organization's game and associated paid content. Players may utilize Game Currency for various in-game purchases, including but not limited to virtual items, enhancements, and premium features. For the facilitation of in-game currency top-up transactions, players are expected to adhere to the following procedure, as set forth by the Organization:</p>
        <p></p>
        <ol>
        <li>
        <p>Players shall access the official website of the Organization, (<a href="https://ranonlinegs.com/">https://ranonlinegs.com/</a>) and navigate to the designated shop section therein;</p>
        </li>
        <li>
        <p>Players are obliged to meticulously select the desired denominations of in-game currencies they intend to recharge and subsequently add them to their virtual shopping cart;</p>
        </li>
        <li>
        <p>Upon completion of the currency selection process, players shall proceed to the checkout stage by selecting the "CART" option available within the menu interface;</p>
        </li>
        <li>
        <p>Players are mandated to complete all requisite information fields presented during the checkout process. Users must provide a valid email address for authentication purposes;</p>
        </li>
        <li>
        <p>Players shall exercise their discretion in selecting their preferred method of payment; and</p>
        </li>
        <li>
        <p>After the selection of the desired payment method, players shall confirm their order by initiating the "Place Order" command. Detailed instructions and transaction specifics will be visible in succeeding stages of the process. Players utilizing GCash for payment are advised to ensure the deactivation of GProtect functionality, as transactions facilitated with GProtect activated will not be processed.</p>
        </li>
        </ol>
        <p></p>
        <p>By adhering to the aforementioned steps, players can effectively initiate the top-up procedure for Game Currency within the parameters established by the Organization. For any inquiries or assistance on the top-up process, Users are encouraged to contact our dedicated customer support team (https://discord.gg/q9sMARmPbf).</p>
        <p></p>
        <p>If in any case, the following subparagraphs apply, the Organization may not accept the application for the charging of RCoins or cancel the approval after the purchase:</p>
        <p></p>
        <ol>
        <li>
        <p>If the payment is not made or the payer is not verified;</p>
        </li>
        <li>
        <p>When the payment information of another person is stolen;</p>
        </li>
        <li>
        <p>If the legal representative does not consent to the minor&rsquo;s application for charging; and</p>
        </li>
        <li>
        <p>If it is deemed inappropriate to approve for a reason equivalent to items one (1) through three (3).</p>
        </li>
        </ol>
        <p></p>
        <p>In the event of a dispute arising between the player and the third-party payment provider regarding the purchase of RCoin, the parties involved shall undertake to resolve the dispute. Under no circumstances shall the Organization be held liable for any obligations incurred by the player to such third-party provider in connection with the player's access and use of the QS Services. You agree to indemnify, defend, and hold harmless the Organization, its affiliates, moderators, game masters, and administrators from all claims for liabilities, losses, and expenses, including reasonable attorneys&rsquo; fees, from third parties arising from such dispute.<br/><br/><strong>SECTION II. WITHDRAWING PAID CONTENTS AND RCOINS &ndash;</strong> The acquisition of RCoins and subsequent in-game purchases, virtual items, enhancements, and /or premium features, is deemed nonrefundable once Users have received the RCoins and/or associated items.</p>
        <p>Should Users initiate a chargeback or request a refund for their purchase, the Organization reserves the right to impose a permanent ban on the User's account. The ban shall only be lifted upon the User's restitution of the amount recharged through withdrawal.</p>
        <p><br/>The User hereby acknowledges that the Organization is not obligated to refund any amounts paid for digital purchases made through the platform or the utilization of the platform.</p>
        <p><br/><strong>SECTION III. DISCLAIMER OF WARRANTIES &ndash;</strong> The User acknowledges that the following terms and conditions govern their utilization of QS Services and the Internet. The Organization provides QS Services, including third-party services and in-game products or items, and the User's utilization of these services is entirely at their own risk. These services are provided without any express or implied warranties, including warranties of merchantability or fitness for a particular purpose. The Organization provides QS Services on a commercially reasonable basis; however, there is always no guarantee of uninterrupted access or use of QS Services or locations chosen by the User. Additionally, the Organization cannot guarantee adequate capacity for QS Services as a whole or in any specific geographic area. The User's access and use of QS Services are solely at their own discretion and risk. The User is solely responsible for any damage to their computer and/or device, loss of in-game products or items, or loss of data resulting from accessing or using QS Services.</p>
        <p><br/><br/><strong>SECTION IV. PURCHASING, REQUESTING AND WITHDRAWING PAID CONTENTS/RCOINS &ndash;</strong> To the maximum extent permitted by applicable law, the Organization explicitly disclaims, and you hereby waive, all warranties and liabilities, whether express or implied, arising by operation of law or otherwise, concerning all QS Services and its contents. These disclaimers include but are not limited to implied warranties of merchantability or fitness for a particular purpose, and tort claims (including negligence). Despite the above, you acknowledge that the Organization does not ensure or guarantee uninterrupted accessibility to the Organization website (<a href="https://ranonlinegs.com/shop">https://ranonlinegs.com/shop</a>) or in-game item shop, nor does it assert that this site, its materials, or the server facilitating its availability are free from errors, defects, design flaws, omissions, viruses, or other harmful components.</p>
        <p></p>
        <p></p>
        <p><strong>ARTICLE 7</strong><br/><strong>CODE OF CONDUCT, OBLIGATIONS OF THE ORGANIZATION, LIMITATIONS OF ORGANIZATION LIABILITY, OBLIGATIONS OF MEMBERS, COMPENSATION FOR DAMAGE, AND NOTICE TO MEMBERS</strong></p>
        <p></p>
        <p><strong>SECTION I. CODE OF CONDUCT &ndash;</strong> Users are obligated to abide by all relevant laws and regulations within their jurisdiction and are expected to adhere to our acceptable behavior and usage policies (referred to as the "Code of Conduct&rdquo;). The Organization retains the prerogative to revise the Code of Conduct, disseminated periodically on our official website and official support group server. The following instances constitute behavior that may warrant disciplinary action:</p>
        <ol>
        <li>
        <p>Users disclosing, soliciting, or attempting to obtain a user ID, password, or other confidential or personal information from another user, including but not limited to passwords and personal information, whether on third-party platforms or elsewhere;</p>
        </li>
        <li>
        <p>Users subjecting other players or members of the QuickSilver team to conditions that render them feeling unsafe, abused, or disrespected;</p>
        </li>
        <li>
        <p>Users who partake in behavior that diminishes, threatens, bullies, insults, abuses, or harasses others, including actions that endorse or foster hateful ideologies, discrimination, denigration, harassment, or violence based on various protected characteristics such as race, ethnicity, religion, sex, gender, gender identity, sexual orientation, age, disability, or serious medical condition;</p>
        </li>
        <li>
        <p>Users engaging in harassment, doxing, threats, abuse, disparagement, libel, slander, embarrassment, or any other form of disruptive behavior directed towards other players or members of the QuickSilver team;</p>
        </li>
        <li>
        <p>Users who attempt to impersonate individuals, other players, or QuickSilver team members, disseminate false information to the Organization or third parties, spread vulgar content, post illegal websites, engage in spam or drug-related activities, or disclose non-public information belonging to us or other users;</p>
        </li>
        <li>
        <p>Users who exploit bugs, distribute unauthorized plugins, or use cheats, bots, hacks, or transmit harmful content, including viruses, corrupted data, trojan horses, bot keystroke loggers, worms, time bombs, cancelbots, or other computer programming routines intended to damage, interfere with, intercept, mine, scrape, or expropriate any system, data, or personal information;</p>
        </li>
        <li>
        <p>Users posting or sharing content that encourages illegal, obscene, lewd, promotional, or otherwise disruptive behavior;</p>
        </li>
        <li>
        <p>Users providing unauthorized payment methods;</p>
        </li>
        <li>
        <p>Users who engage and are found cheating, scamming other users, stealing, or selling unauthorized or illegally obtained in-game products and/or virtual items;</p>
        </li>
        <li>
        <p>Users who induce or encourage others to violate the TOS or these Terms, as well as those who engage in, promote, or encourage any illegal or fraudulent activity, including cheating, buying illegally obtained in-game products and/or virtual items, being an accomplice or protecting individuals engaged in illegal activities within the game, hacking, cracking, or identity theft;</p>
        </li>
        <li>
        <p>Players who disrupt the flow of chat in the in-game chat by using vulgar language, being abusive, repeatedly hitting the return key, excessively shouting, spamming, flooding, or posting repetitive text; and</p>
        </li>
        <li>
        <p>Users who improperly use the official support group server to make false reports to the QuickSilver team.</p>
        </li>
        </ol>
        <p>For violating this Code of Conduct or any of these Terms of Service, the player's family name, character name, etc., may be posted on the official website and other online platforms of the Organization.</p>
        <p><br/>In the event of disputes among Players, the Organization reserves the right, though not obligated, to mediate, resolve, or intervene based on the pertinence or severity of the circumstances.</p>
        <p></p>
        <p>To maintain the integrity and principles of QS Services, the Organization reserves the prerogative to undertake actions, including but not limited to the following sanctions stated below:</p>
        <ol>
        <li>
        <p>Warning;</p>
        </li>
        </ol>
        <ol>
        <li>
        <p>Strike on User&rsquo;s account;</p>
        </li>
        <li>
        <p>Restricting User&rsquo;s account;</p>
        </li>
        <li>
        <p>blocking communications and/or logins;</p>
        </li>
        <li>
        <p>Suspending;</p>
        </li>
        <li>
        <p>Terminating accounts temporarily and permanently (Forfeit of in-game items purchases associated with the said account); or</p>
        </li>
        <li>
        <p>Banning;</p>
        </li>
        </ol>
        <p></p>
        <p>Sanctions for violations will be determined based on the severity of the infraction, with the following escalating penalties applied accordingly:</p>
        <p></p>
        <ol>
        <li>
        <p><strong>Cheating in Farming Activities:</strong></p>
        </li>
        </ol>
        <p>If cheating is confirmed, the following disciplinary actions will be taken:</p>
        <p><strong>First Violation:</strong> 15-day Hall of Justice (HOJ) confinement and temporary ban</p>
        <p><strong>Second Violation:</strong> 30-day HOJ confinement and temporary ban</p>
        <p><strong>Third Violation:</strong> 60-day HOJ confinement and temporary ban</p>
        <p><strong>Fourth Violation:</strong> Permanent ban</p>
        <p></p>
        <ol>
        <li>
        <p><strong>Cheating in Tyranny Wars, Club War, Dungeon, or PvP event or tournament</strong></p>
        </li>
        </ol>
        <p>For verified instances of cheating in Tyranny Wars, Club War, Dungeon, or PvP event or tournament the following penalties will apply:</p>
        <p><strong>First Violation:</strong> 30-day HOJ confinement and temporary ban</p>
        <p><strong>Second Violation:</strong> 60-day HOJ confinement and temporary ban</p>
        <p><strong>Third Violation:</strong> 90-day HOJ confinement and temporary ban</p>
        <p><strong>Fourth Violation:</strong> Permanent ban</p>
        <p></p>
        <p>These guidelines ensure that players who repeatedly violate rules face progressively stringent consequences, thereby promoting a fair and competitive environment for all participants.</p>
        <p></p>
        <p>The Organization hereby reserves and maintains the final right to interpret and act according to relevant circumstances of your inappropriate behavior.</p>
        <p><br/>Should you encounter another user in violation of any items delineated in the Code of Conduct, we kindly request that you promptly report such instances to the administration at<a href="mailto:admin@ranonlinegs.com">admin@ranonlinegs.com</a> . Please ensure that your report includes clear and substantial evidence to substantiate the claim, as reports without adequate proof may not be eligible for investigation.</p>
        <p></p>
        <p>If you believe your account was penalized in error, you may choose to appeal it by contacting us through the applicable game's support page.</p>
        <p><br/>You hereby acknowledge and agree that you bear full responsibility for all actions undertaken under your account. In the event that you are subjected to a warning, penalty, suspension, or any other form of disciplinary action, it is imperative to understand that such determinations are made judiciously.</p>
        <p><br/><br/><strong>SECTION II. OBLIGATIONS OF THE ORGANIZATION &ndash;</strong> We, the QuickSilver team, pledge to do everything within our capacity and power to abide by the laws and regulations, as well as fulfill our obligations as stated below:</p>
        <ol>
        <li>
        <p>We are solemnly bound to uphold and maintain an effective security system to safeguard and protect the personal and confidential information under its custody from any third-party or unauthorized access. We shall utilize the personal and confidential information solely for the purposes delineated within the TOS;</p>
        </li>
        <li>
        <p>During service improvements, we will promptly address equipment damage or data loss, except in cases of force majeure, ensuring continuous efforts to stabilize service by remedying any issues that arise;</p>
        </li>
        <li>
        <p>We shall manage a customer support group or services as specified in Article 1 Section 4. We pledge to provide courteous and efficient assistance to customers with concerns, complaints, and other matters; and</p>
        </li>
        <li>
        <p>We shall endeavor to provide convenience to its users in their procedures, processes, and modifications. Moreover, we shall strive to continuously enhance their service for the benefit of their users.</p>
        </li>
        </ol>
        <p><strong>SECTION III. LIMITATIONS OF ORGANIZATION LIABILITY &ndash;</strong> Users hereby acknowledge that their use of the QS Services is at their own risk. The Organization provides the game(s) and QS Services on an &ldquo;as is'' and &ldquo;as available&rdquo; basis. QuickSilver shall be exempted from responsibility if:</p>
        <ol>
        <li>
        <p>QS Services shall not be carried out in the event of national emergencies, natural disasters, accidents, technically intricate issues, or any other justifiable cause for service interruption;</p>
        </li>
        <li>
        <p>Loss of access to QS Services, as stipulated in Article 4, Section 5, arises from the fault or negligence of the User;</p>
        </li>
        <li>
        <p>Users may encounter problems or issues arising from the mismanagement of personal data, including personally identifiable information or confidential information. Additionally, damages to the service or third-party payments may occur due to the User's negligence in managing passwords or authentication methods, or from failing to pay sufficient attention to prevent misuse, manipulation/ tampering, or falsification;</p>
        </li>
        <li>
        <p>Users may not get the results they expect when using all QS Services, including experiences, accounts, characters, items, and any other related services, or experience loss thereof;</p>
        </li>
        <li>
        <p>Loss of game data owned by the users, such as their virtual items and purchased game currency, levels, or other related items in the Game World, due to their negligence;</p>
        </li>
        <li>
        <p>Dispute arises between users or between a user and a third party, whether related to the game or not, within or outside the Virtual Game, it is not the responsibility of the Organization to intervene or mediate, nor to assume any liability for any damages that may be caused or incurred;</p>
        </li>
        <li>
        <p>The complimentary in-game items (commonly referred to as "gift box", "sorry box", or "compensation box") provided by the Organization are lost due to either the negligence of the player or the occurrence of technical issues encountered by the player; and</p>
        </li>
        <li>
        <p>Users have deleted the content or account information provided by the Organization and subsequently seek its retrieval;</p>
        </li>
        </ol>
        <p><br/>Users acknowledge and accept that their utilization of the QS Services entails inherent risks. The Organization presents the game(s) and QS Services in their current condition, available as per their availability. To the fullest extent permitted by applicable laws, the Organization and/or its affiliates, freelancers, game masters, moderators, streamers, and administrators disclaim all warranties. This covers warranties on merchantability, error-free functionality, or suitability for a specific purpose, whether stated explicitly or implied, regardless of the legal basis (contractual, tort, or otherwise), and regardless of whether the Organization has been notified of such potential liabilities.</p>
        <p><br/><strong>SECTION IV. OBLIGATIONS OF PLAYERS &ndash;</strong>As players and registered users utilizing QS Services, Users must adhere to the following obligations:</p>
        <ol>
        <li>
        <p>Users are required to review and adhere to the provisions outlined in these Terms, notices posted on the official website, official support group server, any separate policies established by the Organization;</p>
        </li>
        <li>
        <p>Users are obliged to abide by all provisions outlined in these terms and conditions. Players found to have contravened these Terms may be subject to restrictions on their use of the QS Service;</p>
        </li>
        <li>
        <p>Users shall inform or notify the Organization of any issues experienced with the server or encountered errors, bugs, or system problems observed or noticed while utilizing QS Services;</p>
        </li>
        <li>
        <p>Specific guidelines that Users must adhere to while playing the game or when utilizing QS Services shall be under the Separate Operational Policy;</p>
        </li>
        <li>
        <p>Users are prohibited from obtaining game data, through unauthorized methods or asserting rights over it; and</p>
        </li>
        <li>
        <p>Users are accountable for maintaining the security of their accounts, including safeguarding their passwords, utilizing a valid and active email address, and refraining from disclosing other confidential information. The Organization cannot be held liable for incidents wherein issues occur due to a third-party utilizing account information as a result of negligence in personal information management.</p>
        </li>
        </ol>
        <p></p>
        <p><strong>SECTION V. COMPENSATION FOR DAMAGE &ndash;</strong> If Users fail to comply with all the agreements outlined in this TOS, and they cause significant damage to the Organization, most especially in the event of any threat and actual breach of these Terms, each User acknowledges and accepts that QuickSilver may seek injunctive relief from the court or any payment, depending on the violation committed.</p>
        <p>For violating any of these Terms of Service, the player's family name, character name, etc., may be posted on the official website and other online platforms of the Organization.</p>
        <p><br/><strong>SECTION VI. NOTICE TO USERS</strong> <strong>&ndash;</strong> In the event of any dispute arising between you and a third party, it is agreed that resolution shall be sought solely between you and the aforementioned third party. The Organization explicitly disclaims any liability for obligations incurred by you to said third party concerning your use of the QS Services. By accessing and utilizing the QS Services, you expressly agree to indemnify, defend, and hold harmless the Organization, its affiliates, moderators, game masters, developers, and administrators from all claims, liabilities, losses, and expenses, including reasonable attorneys&rsquo; fees, arising from such disputes with third parties.</p>
        <p><br/><br/><strong></strong><strong>ADDENUM</strong><br/>This Agreement will be effective as of 1 March 2024.</p>
        <p></p>
      </Text>
      <Text>
        By checking this box, you agree to our <span style={{ color: 'red' }}>Terms of Service</span>
      </Text>
      <CheckboxContainer>
        <Checkbox
          type="checkbox"
          checked={isAgreed}
          onChange={handleCheckboxChange}
        />
        I agree to the Terms of Service
      </CheckboxContainer>
      <ProceedButton onClick={handleProceed} disabled={!isAgreed}>
        Proceed to Register
      </ProceedButton>
    </Container>
  );
};

export default TermsOfServicePage;
