import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { UserContext } from '../components/UserContext'; // Import the UserContext

const FormContainer = styled.div`
  width: 20%;
  background-color: #1c1c1c; /* Darker background */
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 5px 5px 0 rgba(0, 0, 0, 0.3); /* Adjusted shadow */
  padding: 10px;
  margin-top: 200px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SuccessMessage = styled.div`
  font-family: "Roboto", sans-serif;
  background-color: #2e7d32; /* Greenish background for success */
  padding: 15px;
  color: white;
  text-align: center;
`;

const FormField = styled.input`
  width: 90%; /* Full width of container */
  margin: 0; /* Remove default margin */
  padding: 15px;
  font-size: 16px;
  border: 1px solid #444; /* Dark border for input */
  border-radius: 4px; /* Rounded corners */
  font-family: "Roboto", sans-serif;
  background: #333; /* Darker background for input */
  color: #eee; /* Lighter text color for input */
  
  &.error {
    border-color: #ff6f61; /* Red border for errors */
  }
`;

const ErrorSpan = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ff6f61; /* Red color for error messages */
  margin: 5px 0; /* Margin for spacing between field and error */
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  background: #273c75; /* Teal background for button */
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px; /* Rounded corners */
  font-family: "Roboto", sans-serif;

  &:disabled {
    cursor: not-allowed;
    background: #004d40; /* Darker teal for disabled state */
  }
`;

const FormFieldContainer = styled.div`
  width: 100%; /* Full width of container */
  margin-bottom: 20px; /* Space below each field */
`;

const Login = React.memo(() => {
  
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const [values, setValues] = useState({ userId: '', password: '' });
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const location = useLocation();
  const dropdownRef = useRef({});
  const loginDropdownRef = useRef(null);
  
  const { userData, setUserData } = useContext(UserContext);

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [serverError, setServerError] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const navigate = useNavigate();

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setValues((values) => ({
      ...values,
      [name]: value
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const validationErrors = validateForm(values);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Se
      setValid(false);
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
        body: JSON.stringify({
          userId: values.userId,
          password: values.password,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('userData', JSON.stringify(data.user));
        setUserData(data.user);
        setIsLoggedIn(true); // Set the login state to true
        navigate('/');
        window.location.reload();
  
        // Calculate the expiration time (1 hour in the future)
        const expirationTime = Date.now() + 30 * 60 * 1000; // 1 hour in milliseconds
        localStorage.setItem('logoutExpiration', expirationTime);
  
        // Optionally, store the logout timer ID
        const logoutTimer = setTimeout(() => {
          handleLogout();
        }, 30 * 60 * 1000); // 1 hour in milliseconds
  
        localStorage.setItem('logoutTimer', logoutTimer);
      } else {
        setServerError(data.error || "Registration failed");
        setValid(false);
      }
    } catch (error) {
      setServerError('Something went wrong. Please try again later.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove the token on logout
    localStorage.removeItem('userData');
    localStorage.removeItem('logoutExpiration'); // Clear expiration time
    localStorage.removeItem('logoutTimer'); // Clear the stored timer ID
    setIsLoggedIn(false); // Reset login state
    setUserData(null);
    setLoginDropdownOpen(false); // Close the dropdown
    setValues({ userId: '', password: '' }); // Clear login form
    setErrors({});
    window.location.reload();
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.userId) errors.userId = "Please enter a user ID";
    if (!values.password) errors.password = "Please enter a password";
    
    return errors;
  };

  return (
    <FormContainer>
      <LoginForm onSubmit={handleSubmit}>

            <FormFieldContainer>
              <FormField
                type="text"
                placeholder="User ID"
                name="userId"
                value={values.userId}
                onChange={handleInputChange}
                className={errors.userId ? "error" : ""}
              />
              {errors.userId && (
                <ErrorSpan id="user-id-error">{errors.userId}</ErrorSpan>
              )}
            </FormFieldContainer>
            <FormFieldContainer>
              <FormField
                type="password"
                placeholder="Password"
                name="password"
                value={values.password}
                onChange={handleInputChange}
                className={errors.password ? "error" : ""}
              />
              {errors.password && (
                <ErrorSpan id="password-error">{errors.password}</ErrorSpan>
              )}
            </FormFieldContainer>
            {serverError && (
              <ErrorSpan>{serverError}</ErrorSpan> // Display server error message
            )}
            <SubmitButton type="submit">
              Login
            </SubmitButton>
      </LoginForm>
    </FormContainer>
  );
});

export default Login;