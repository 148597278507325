import React, { useState } from 'react';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';

// Styled components for Dropdown
const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 94%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const DropdownList = styled.div`
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
  margin: 0;
  padding: 0;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropdownTextbox = ({ usernames, onUsernameSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsDropdownOpen(true);
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    setIsDropdownOpen(false);
    onUsernameSelect(option); // Call the passed function
  };

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 100); // Delay to allow option click to register
  };

  // Filtered usernames based on input value
  const filteredUsernames = usernames.filter((username) =>
    username.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <DropdownContainer>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder="Type or select a username"
      />
      {isDropdownOpen && filteredUsernames.length > 0 && (
        <DropdownList>
          <List
            height={150} // Set the height of the dropdown list
            itemCount={filteredUsernames.length}
            itemSize={35} // Height of each dropdown item
            width="100%"
          >
            {({ index, style }) => (
              <DropdownItem
                key={filteredUsernames[index]}
                style={style}
                onClick={() => handleOptionClick(filteredUsernames[index])}
              >
                {filteredUsernames[index]}
              </DropdownItem>
            )}
          </List>
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default DropdownTextbox;



// import React, { useState } from 'react';
// import styled from 'styled-components';

// // Styled components for Dropdown
// const DropdownContainer = styled.div`
//   position: relative;
//   width: 100%;
// `;

// const Input = styled.input`
//   width: 94%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   font-size: 16px;
// `;

// const DropdownList = styled.ul`
//   position: absolute;
//   width: 100%;
//   max-height: 150px;
//   overflow-y: auto;
//   background: white;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   z-index: 1;
//   margin: 0;
//   padding: 0;
//   list-style: none;
// `;

// const DropdownItem = styled.li`
//   padding: 10px;
//   cursor: pointer;

//   &:hover {
//     background-color: #f0f0f0;
//   }
// `;

// const DropdownTextbox = ({ usernames, onUsernameSelect }) => {
//   const [inputValue, setInputValue] = useState('');
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//     setIsDropdownOpen(true);
//   };

//   const handleOptionClick = (option) => {
//     setInputValue(option);
//     setIsDropdownOpen(false);
//     onUsernameSelect(option); // Call the passed function
//   };

//   const handleInputFocus = () => {
//     setIsDropdownOpen(true);
//   };

//   const handleInputBlur = () => {
//     setTimeout(() => {
//       setIsDropdownOpen(false);
//     }, 100); // Delay to allow option click to register
//   };

//   return (
//     <DropdownContainer>
//       <Input
//         type="text"
//         value={inputValue}
//         onChange={handleInputChange}
//         onFocus={handleInputFocus}
//         onBlur={handleInputBlur}
//         placeholder="Type or select a username"
//       />
//       {isDropdownOpen && (
//         <DropdownList>
//           {usernames
//             .filter((username) => username.toLowerCase().includes(inputValue.toLowerCase()))
//             .map((username) => (
//               <DropdownItem
//                 key={username}
//                 onClick={() => handleOptionClick(username)}
//               >
//                 {username}
//               </DropdownItem>
//             ))}
//         </DropdownList>
//       )}
//     </DropdownContainer>
//   );
// };

// export default DropdownTextbox;
