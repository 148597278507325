import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPaperPlane,FaShoppingCart, FaRegistered } from '@fortawesome/free-solid-svg-icons';

import DropdownTextbox from '../components/DropdownTextbox';
import SearchableDropdown from '../components/SearchableDropdown';
import { UserContext } from '../components/UserContext'; // Adjust the import as necessary
import {
    LabelPrimaryStyled,
    LabelDangerStyled,
    LabelSuccessStyled,
} from '../utils/LabelUtils';

const CharacterList = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap */
  gap: 20px; /* Space between cards */
`;

const CharacterImage = styled.img`
  width: 60px; /* Set a width for the character image */
  height: 60px; /* Set a height for the character image */
  margin-right: 15px; /* Space between image and text */
`;

// Styled components (reuse from UserDetailsTable)
// [Other styled components stay the same]

// Pagination buttons styling
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: #444; /* Dark gray button */
  color: #ffffff; /* White text */
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #555; /* Slightly lighter gray on hover */
  }

  &:disabled {
    background-color: #666; /* Disabled state */
    cursor: not-allowed;
  }
`;


const Card = styled.div`
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  width: 100%; /* Adjust width as needed */
`;

const CharacterCard = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const NewDataText = styled.span`
  display: inline-block;
  max-width: 200px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin-left: 20px; /* Add some space to the right */
  background-color: #c9302c; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  width: 20%;
  justify-content: center;

  &:hover {
    background-color: #e74c3c; /* Lighter green on hover */
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: #fefefe;
  padding: 30px;
  border-radius: 12px;
  width: 350px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Space buttons evenly */
  margin-top: 20px;
`;

const CloseButton = styled.button`
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: #c0392b;
  }
`;

const BuyButton = styled.button`
  background: #27ae60;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: #219653;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; /* Make the wrapper full width */
`;

const LabelStyled = styled.label`
  margin-bottom: 10px;
  color: #333;
`;

const DropdownStyled = styled(DropdownTextbox)`
  width: 100%; /* Make the dropdown full width */
`;

const StyledSelect = styled.select`
  width: 100%; /* Make the select full width */
  padding: 10px; /* Add padding */
  border: 1px solid #ccc; /* Add border */
  border-radius: 5px; /* Round the corners */
  font-size: 16px; /* Font size */
  color: #333; /* Text color */
  background-color: #fff; /* Background color */
  transition: border-color 0.3s;

  &:focus {
    border-color: #3498db; /* Border color on focus */
    outline: none; /* Remove default outline */
  }
`;




// UserPanelEdit component
const SendPanelList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [sendLog, setSendLogs] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust the number of items per page
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemNames, setItemNames] = useState([]);
  const [selectedProductNum, setSelectedProductNum] = useState('');
  const [selectedItemName, setSelectedItemName] = useState('');
  const [itemSearch, setItemSearch] = useState('');
  const [modalTitle, setModalTitle] = useState(''); // New state for modal title
  const [selectedUsername, setSelectedUsername] = useState('');
  const { userData, setUserData } = useContext(UserContext);
  // Calculate the total number of pages
  const totalPages = Math.ceil(sendLog.length / itemsPerPage);
  const [isLoading, setIsLoading] = useState(false); // Add a loading state


  // Fetch character list data from API
  const fetchSendLog = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sendlog`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
      });
      const data = await response.json();
      setSendLogs(data.sendlog); // Assuming API returns an object with a 'sendlog' array
    } catch (error) {
      console.error('Error fetching characters:', error);
    }
  };
  

  const fetchUsernames = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/usernames`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
      });
      const data = await response.json();
  
      // Extract usernames from the objects in the array
      if (Array.isArray(data.usernames)) {
        const extractedUsernames = data.usernames.map(item => item.username); // Extract the username property
        setUsernames(extractedUsernames); // Update the state with the array of strings
      } else {
        console.error('Usernames is not an array:', data.usernames);
      }
    } catch (error) {
      console.error('Error fetching usernames:', error);
    }
  };

  const fetchItems = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/itemnames`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
        });
        const data = await response.json();

        // Check if itemnames is an array and extract item names and product numbers
        if (Array.isArray(data.itemnames)) {
            const extractedItems = data.itemnames.map(item => ({
                productnum: item.productnum,
                itemname: item.itemname
            })); // Create an array of objects
            setItemNames(extractedItems); // Update the state with the array of objects
        } else {
            console.error('Itemnames is not an array:', data.itemnames);
        }
    } catch (error) {
        console.error('Error fetching item names:', error);
    }
};

    const handleSelectChange = (e) => {
        const selectedItem = itemNames.find(item => item.itemname === e.target.value);
        setSelectedItemName(e.target.value); // Update selected item name
        setSelectedProductNum(selectedItem ? selectedItem.productnum : ''); // Update selected product number
    };

    const handleUsernameSelect = (username) => {
        setSelectedUsername(username);
    };


    useEffect(() => {
      const fetchData = async () => {
        await Promise.all([fetchItems(), fetchUsernames(), fetchSendLog()]);
      };
      fetchData();
    }, []); // The empty dependency array ensures this runs only once on mount

      // Handle add item button click
      const handleAddItem = () => {
    };
  
    const handleBuyClick = (buttonText) => {
        setModalTitle(buttonText); // Set the modal title based on button text
        setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    const handleBuy = async () => {
      if (!selectedUsername || !selectedProductNum) {
        alert("Please select a username and an item before proceeding.");
        return;
      }

      setIsLoading(true); // Set loading to true when the operation starts
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shoppurchase`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
                },
                body: JSON.stringify({
                username: selectedUsername,
                productnum: selectedProductNum,
                }),
            });
            if (response.ok) {
              try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/adminlog/add`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
                    },
                    body: JSON.stringify({
                    username: selectedUsername,
                    type: 'Input Item',
                    newdata: selectedItemName,
                    olddata: '-',
                    adminname: userData.username,
                    }),
                });
                if (response.ok) {
                  fetchSendLog();
                  alert(`${selectedItemName} has been successfully delivered to ${selectedUsername}.`);
                } else {
                }
                } catch (err) {
              }
            } else {
            }
            
            } catch (err) {
        } finally {
          setIsLoading(false); // Set loading to false after the operation completes
          handleCloseModal();
        }

    };

  // Get the current items for the current page
  const currentItems = sendLog.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle next page
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Handle previous page
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Filter items based on the search input
const filteredItems = itemNames.filter(item => 
    item.itemname.toLowerCase().includes(itemSearch.toLowerCase())
  );

  return (
    <>
    
    <tr style={{ height: '20px',padding: '20px' }} /> {/* Adds space between rows */}
    {/* Button click handlers updated */}
   <center><AddButton onClick={() => handleBuyClick('Send Item to all player')}>
        <FontAwesomeIcon icon={faPaperPlane} />
        Send Item to all player
      </AddButton>
      <AddButton onClick={() => handleBuyClick('Send Item to online player')}>
        <FontAwesomeIcon icon={faPaperPlane} />
        Send Item to online player
      </AddButton>
      <p></p>
      <AddButton onClick={() => handleBuyClick('Send Item to lvl 200 player')}>
        <FontAwesomeIcon icon={faPaperPlane} />
        Send Item to lvl 200 player
      </AddButton>
      <AddButton onClick={() => handleBuyClick('Send Item to specific player')}>
        <FontAwesomeIcon icon={faPaperPlane} />
        Send Item to specific player
      </AddButton>
      {/* Character List Card */}</center> 
      <Card>
        <h2 style={{ color: 'white' }}>Send Log</h2>
        <CharacterList>
          {currentItems?.length > 0 ? (
            currentItems.map((character, index) => (
              <CharacterCard key={index}>
                <CharacterImage
                  src={`/images/item/${character.itemsub}.png`}
                  style={{ height: '70px' }}
                  alt={`${character.chaname}`}
                /> {/* Assuming character.chaimage holds the image filename */}
                <div>

                    <h3>Date: {formatDate(character.created_at)}</h3>
                    <p>
                        Item: <NewDataText>{character.newdata}</NewDataText>
                    </p>
                    <p>
                        To: <span style={{ color: 'red', fontWeight: 'bold' }}>{character.username}</span>
                    </p>
                    <p>
                        From: <LabelSuccessStyled>{character.adminname}</LabelSuccessStyled>
                    </p>
                    </div>
              </CharacterCard>
            ))
          ) : (
            <p style={{ color: 'white' }}>No characters found.</p>
          )}
        </CharacterList>

          {/* Pagination Controls */}
      <PaginationWrapper>
        <PaginationButton
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </PaginationButton>

        {/* Display current page number */}
        <span style={{ color: 'white', padding: '10px' }}>
          Page {currentPage} of {totalPages}
        </span>

        <PaginationButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </PaginationButton>
      </PaginationWrapper>
      </Card>
      {isModalOpen && (
        <Overlay>
          <ModalContainer>
          <h3>{modalTitle}</h3> {/* Use modalTitle for the modal heading */}
          
          <tr style={{ height: '20px' }} /> {/* Adds space between rows */}
            <DropdownWrapper>
              <LabelStyled>Username:</LabelStyled>
              <DropdownTextbox usernames={usernames} onUsernameSelect={handleUsernameSelect} />
            </DropdownWrapper>
            <tr style={{ height: '20px' }} /> {/* Adds space between rows */}
            <DropdownWrapper>
              <LabelStyled>Item:</LabelStyled>
              <SearchableDropdown 
                items={itemNames}
                onSelect={(selectedItem) => {
                  setSelectedItemName(selectedItem.itemname);
                  setSelectedProductNum(selectedItem.productnum);
                }} 
              />
            </DropdownWrapper>

            <ButtonContainer>
              <CloseButton onClick={handleCloseModal}>Cancel</CloseButton>
              <BuyButton onClick={handleBuy} disabled={isLoading}>
                {isLoading ? 'Processing...' : 'Buy'}
              </BuyButton> {/* Disable button when loading */}
            </ButtonContainer>
          </ModalContainer>
        </Overlay>
      )}
    </>
  );
};

const formatDate = (dateString) => {
  // Create a Date object from the UTC date string
  const date = new Date(dateString);

  // Get the year, month, and day in UTC
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');

  // Get the hours, minutes, and seconds in UTC
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return in YYYY-MM-DD HH:MM:SS format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export default SendPanelList;
