import React from 'react';
import styled from 'styled-components';

// Container for the whole table section
export const Container = styled.div`
  margin-top: 50px;
`;

// Responsive table wrapper
export const TableResponsive = styled.div`
  margin-bottom: 10px;
  align-items: -webkit-center;
`;

// Table with borders
export const Table = styled.table`
  width: 70%;
  margin-bottom: 1rem;
  background-color: rgba(0,0,0,0.7);
  border-collapse: collapse;
`;

// Table borders
export const TableBordered = styled(Table)`
  border: 1px solid #dee2e6;

  td {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle; /* Ensures vertical alignment of text */
  }
`;

// Center content horizontally and vertically in table cells
export const TextCenter = styled.td`
  justify-content: center; /* Horizontal alignment */
  align-items: center;     /* Vertical alignment */
  text-align: center;      /* Center text */
`;

// Main text color
export const TextMain1 = styled.span`
  color: red; /* Adjust this color to match your theme */
`;

// Image container
export const NkPostImage = styled.a`
  img {
    max-width: 100%;
    height: auto;
  }
`;

// Icon styling
export const FaDiamond = styled.i`
  color: #f39c12; /* Adjust icon color */
`;

export const FaTrash = styled.i`
  color: #e74c3c; /* Adjust icon color */
`;

// Gap elements
export const NkGap = styled.div`
  margin-bottom: 20px;
`;

export const NkGap2 = styled(NkGap)`
  margin-bottom: 30px;
`;

// Button alignment
export const AlignRight = styled.div`
  text-align: right;
`;

// Rounded button
export const BtnRounded = styled.button`
  border-radius: 50px;
  background-color: #3498db; /* Adjust button color */
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9; /* Adjust button hover color */
  }
`;
// Create a styled Font Awesome icon with warning color
export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

const CartTable = ({id,kbpoint,price,logo,currencySign}) => {
    return (
      <Container>
        <TableResponsive>
          <TableBordered>
            <tbody className="page_speed_1122359564">
              <tr>
                <TextCenter>
                  <NkPostImage>
                    <img
                      src={logo}
                      alt=""
                      width="50"
                    />
                  </NkPostImage>
                </TextCenter>
                <TextCenter>
                  <TextMain1>
                  <span
                    style={{
                      marginRight: '10px'  // Adjust the value as needed
                    }}
                  >
                    <FaRegistered className="fas fa-registered" />
                  </span>
                    <strong style={{ fontSize: '24px' }}>{kbpoint.toLocaleString()} rcoins</strong>
                    </TextMain1>
                </TextCenter>
                <TextCenter>
                  <TextMain1><span
                    style={{
                      marginRight: '10px'  // Adjust the value as needed
                    }}
                  >
                    {currencySign}
                  </span>
                  <strong style={{ fontSize: '24px' }}>{price.toLocaleString()}</strong></TextMain1>
                </TextCenter>
                <TextCenter>
                <a href="/recharge" onClick={(e) => {
                  e.preventDefault();
                  window.history.back();
                }}>
                  <FaTrash className="fa fa-trash" aria-hidden="true" />
                </a>
                </TextCenter>
              </tr>
            </tbody>
          </TableBordered>
        </TableResponsive>
        <NkGap2></NkGap2>
        <NkGap></NkGap>
      </Container>
    );
};

export default CartTable;
