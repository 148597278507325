import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

// Styled-components for structure and styling
const PatchNotesContainer = styled.div`
  font-family: 'Helvetica', 'Arial', sans-serif;
  padding: 50px;
  background-color: rgba(0,0,0,0.7);
  border-radius: 8px;
  margin: 20px 150px;
  color:  white; 
  position: relative;
`;

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  color: #2c3e50;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-top: 20px;
  color: #333;
`;

const ListItem = styled.div`
  font-size: 16px;
  margin-left: 20px;
  color: #555;
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  color: #e74c3c;
`;

// Styled component for the Go Back button
const GoBackButton = styled.button`
  position: absolute; /* Position it absolutely within the container */
  top: 20px;
  right: 20px;
  background-color: #273c75; /* Button background color */
  color: white; /* Button text color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
    
  &:hover {
    background-color: #0056b3; /* Darker background on hover */
  } 
   
`;

const AnnouncementDetail = () => {
  const location = useLocation(); // Get the location object
  const {date, title, description } = location.state || {};  
  const navigate = useNavigate(); // Get the navigate function

  // Function to handle going back
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <PatchNotesContainer>
      <GoBackButton onClick={handleGoBack}>Go Back</GoBackButton>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </PatchNotesContainer>
  );
};

export default AnnouncementDetail;