import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";


// Styled components
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 10%;
`;

const TableWrapper = styled.div`
  border-radius: 2px;
  overflow: hidden;
  background-color: rgba(0,0,0,0.7); /* Dark theme */
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(0,0,0,0.7); /* Dark theme */

  th,
  td {
    padding: 15px;
    text-align: center;
    border: 1px solid #34495e;
    color: #ecf0f1;
  }

  thead th {
    background-color: rgba(0, 0, 0, 0.7);
  }

  tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const PurchaseButton = styled.button`
  background-color: #e74c3c;
  border: 1px solid #e74c3c;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b;
  }
`;

const ButtonLink = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: ${(props) => props.bgColor || "#3498db"};
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: ${(props) => props.hoverColor || "#2980b9"};
    transform: scale(1.05);
  }
`;

// Styled Image
const HeaderImage = styled.img`
  display: block;
  margin: 0 auto;
`;

// Create a styled Font Awesome icon with warning color
export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

// Main component
const RechargePage = () => {
  const [kbvoucher, setKBvoucher] = useState([]);
  let currencySign;

  const fetchKBVoucher = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/kbvoucher`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setKBvoucher(data.kbvoucher); // Ensure it's an array
    } catch (error) {
      console.error("Error fetching KB voucher:", error);
    }
  };

  useEffect(() => {
    fetchKBVoucher();
  }, []);

  const basePath = '../images/payment/';

  // Grouping vouchers by gateway and filtering by status
  const vouchersByGateway = kbvoucher.reduce((acc, item) => {
    if (item.status === '1') { // Check if the status is 1
      if (!acc[item.gateway]) {
        acc[item.gateway] = [];
      }
      acc[item.gateway].push(item);
    }
    return acc;
  }, {});

  return (
    <GridContainer>
      {Object.keys(vouchersByGateway).map((gateway) => (
        <TableWrapper key={gateway}>
          <center><HeaderImage src={`${basePath}${gateway}.png`} height="80" alt="Header Icon" /></center>
          <StyledTable>
            {/* <thead>
              <tr>
                <th>KB Points</th>
                <th>Price</th>
                <th>Purchase</th>
              </tr>
            </thead> */}
            <tbody>
  {vouchersByGateway[gateway].map((item) => {
    const kbpointInt = +item.kbpoint; // Move this line outside of JSX

    return (
      <tr key={item.id}>
        {/* <td>
          <HeaderImage src={`${basePath}${item.image}`} height="20" alt="Header Icon" />
        </td> */}
        <td>
          <FaRegistered className="fas fa-registered" style={{ marginRight: '10px' }} />
          <strong style={{ fontSize: '24px' }}>{kbpointInt.toLocaleString()}</strong>
        </td>
        <td>
          <strong style={{ fontSize: '24px'}}>
            {(() => {
              // Determine the currency sign based on the gateway
              

              if (gateway === 'wise') {
                currencySign = '₱';
              } else if (gateway === 'paypal') {
                currencySign = '$';
              } 

              return (
                <span
                  style={{
                    color:
                      currencySign === '$' ? 'blue' :
                      currencySign === '₱' ? 'green' :
                      'orange',
                    marginRight: '10px' // Adjust the value as needed
                  }}
                >
                  {currencySign}
                </span>
              );
                    })()}
                    {item.price.toLocaleString()}
                </strong>
            </td>
                <td>
                <ButtonLink
                    to={{
                    pathname: `/cart/${item.id}`,
                    }}
                    state={{
                    id: item.id,
                    kbpoint: item.kbpoint,
                    price: item.price,
                    logo: item.logo,
                    currencySign: currencySign,
                    }}
                    bgColor="#60ba60"
                    hoverColor="#4cae4f"
                >
                    <FontAwesomeIcon icon={faShoppingCart} style={{ marginRight: "8px" }} />
                    Purchase
                </ButtonLink>
                </td>
            </tr>
            );
        })}
        </tbody>
          </StyledTable>
        </TableWrapper>
      ))}
    </GridContainer>
  );
};

export default RechargePage;
