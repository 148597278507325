import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { faEdit,faPlusCircle  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate} from 'react-router-dom';
import { getNewsCategoryColor, getNewsCategoryName } from '../utils/NewsCategoryUtils.js';
import {
  LabelPrimaryStyled,
  LabelDangerStyled,
  LabelSuccessStyled,
} from '../utils/LabelUtils';


// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center align content now that there's only one section */
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1; /* Adjusted to fill available space */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 20px; /* Increase padding for larger buttons */
  margin: 0 8px; /* Adjust margin if needed */
  background-color: ${(props) => (props.active ? '#2c3e50' : '#bdc3c7')};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px; /* Increase font size if needed */

  &:hover {
    background-color: #2c3e50;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7f8c8d;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

const MidSidHeader = styled(TableHeader)`
  width: 5%; /* Set the desired width for the Mid Sid header */
`;

const MidSidData = styled(TableData)`
  width: 5%; /* Set the same width for the Mid Sid data cell */
`;

const Dropdown = styled.select`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #27ae60; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #2ecc71; /* Lighter green on hover */
  }
`;

const PurchaseLogButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #c9302c; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #e74c3c; /* Lighter green on hover */
  }
`;

// Main component
const NewsPanelList = () => {
  const [itemList, setItemList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState(''); // Add category state
  const [categoryList, setCategoryList] = useState([]); // Store categories
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const navigate = useNavigate(); // For navigation
  const [news, setNews] = useState([]);

  // Simulate API call to fetch items and categories
  useEffect(() => {
    const fetchNews = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/newspanel`, {
              headers: {
                'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
              },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setNews(data.news);
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };
    fetchNews();

     // Combine categories with both name and color
    const categories = [1, 2, 3, 4].map((id) => ({
        id,
        name: getNewsCategoryName(id),
        color: getNewsCategoryColor(id),
    }));

    setCategoryList(categories); // Set the category list
  }, []);

   // Filter items based on the search term and category
   // Filter items based on the search term and category
    const filteredNews = news
    .filter((item) => {
    const matchesSearchTerm = item.title.toLowerCase().includes(searchTerm.toLowerCase()); // Filter by title or other fields
    const matchesCategory = category === '' || item.category === category; // Filter by category, if selected
    return matchesSearchTerm && matchesCategory; // Match both search and category
    });


  // Get current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemList = filteredNews.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredNews.length / itemsPerPage);

  const getPaginationItems = () => {
    const pageNumbers = [];
    const visiblePages = 3; // Number of pages to show before and after the current page

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      if (currentPage > visiblePages + 2) pageNumbers.push('ellipsis-left');
      for (let i = Math.max(2, currentPage - visiblePages); i <= Math.min(totalPages - 1, currentPage + visiblePages); i++) {
        pageNumbers.push(i);
      }
      if (currentPage < totalPages - visiblePages - 1) pageNumbers.push('ellipsis-right');
      pageNumbers.push(totalPages);
    }
    return pageNumbers.filter((item, index, self) => index === self.indexOf(item));
  };

   // Handle add item button click
   const handleAddItem = () => {
    navigate('/news/add'); // Navigate to add item page (change URL as needed)
  };

  return (
    <PageContainer>
      <ContentWrapper>
        <RankingContainer>
          <Title>News Panel - List</Title>
          <AddButton onClick={handleAddItem}>
              <FontAwesomeIcon icon={faPlusCircle} />
              Create News
          </AddButton>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '20px' }}></div>
          <SearchInput
            type="text"
            placeholder="Search by title..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Dropdown value={category} onChange={(e) => setCategory(e.target.value)}> // No need for Number()
            <option value="">All Categories</option>
            {categoryList.map((cat) => (
                <option key={cat.id} value={cat.name}>{cat.name}</option> // Ensure value is the name (string) or modify based on how your categories are stored
            ))}
            </Dropdown>
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>Category</TableHeader>
                <TableHeader>Title</TableHeader>
                <TableHeader>Time</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Action</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentItemList.map((rankItem) => (
                <TableComponent key={rankItem.id} 
                rankItem={rankItem}
                setNews={setNews} />
              ))}
            </tbody>
          </RankingTable>

          {/* Pagination */}
          <Pagination>
            <PageButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </PageButton>

            {getPaginationItems().map((pageNumber, index) => {
              if (pageNumber === 'ellipsis-left' || pageNumber === 'ellipsis-right') {
                return <Ellipsis key={index}>...</Ellipsis>;
              }
              return (
                <PageButton key={pageNumber} active={currentPage === pageNumber} onClick={() => handlePageChange(pageNumber)}>
                  {pageNumber}
                </PageButton>
              );
            })}

            <PageButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </PageButton>
          </Pagination>
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

const UpdateButton = styled(Button)`
  background-color: #c9302c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #e74c3c;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Distribute space evenly between columns */
  align-items: flex-start; /* Align both itemmain and itemsub to the bottom */
`;


const TableComponent = ({ rankItem , setNews }) => {
  const navigate = useNavigate();
    // Define your base path for images
    const campusBasePath = '../images/campus/'; // Adjust the path according to your folder structure
  
    // Generate the complete image path
    const imageCampusPath = `${campusBasePath}nocamp.png`;

    const handleUpdateStatus = async (id, currentStatus) => {
      const statusName = currentStatus === 1 ? 'Active' : 'Hidden';
      const newStatus = currentStatus === 1 ? 0 : 1; // Toggle the status
    
      const confirmation = window.confirm(
        `The current status is ${statusName}. Do you want to ${currentStatus === 1 ? 'hide' : 'activate'} this item?`
      );
    
      if (confirmation) {
        const updatedNews = { status: newStatus };
    
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/news/status/update/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
            },
            body: JSON.stringify(updatedNews),
          });
    
          if (!response.ok) {
            throw new Error('Failed to update item');
          }

          setNews(prevNewsData =>
            prevNewsData.map(news =>
              news.id === id ? { ...news, status: newStatus } : news
            )
          );
          alert('Status updated successfully');
        } catch (error) {
          console.error('Error updating news:', error);
        }
      }
    };
    
  
    return (
        <TableRow>
            <TableData><span style={{  color: 'white', padding: '5px 10px' ,borderRadius: '4px',fontSize: '14px' ,backgroundColor: getNewsCategoryColor(rankItem.category) }}>{rankItem.category}</span></TableData>
            <TableData>{rankItem.title}</TableData>
            <TableData>{formatDate(rankItem.created_at)}</TableData>
            <TableData>
              {rankItem.status === 1 ? (
              <LabelSuccessStyled>Active</LabelSuccessStyled>
              ) : <LabelDangerStyled>Hidden</LabelDangerStyled>}
              <UpdateButton style={{ marginLeft: '10px' }} onClick={() => handleUpdateStatus(rankItem.id,rankItem.status)}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </UpdateButton>
            </TableData>
            <TableData>
              <UpdateButton onClick={() => navigate(`/news/${rankItem.id}`, { state: { rankItem } })}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </UpdateButton>
            </TableData>

            
            {/* <TableData> 
              <FaRegistered style={{ marginRight: '10px' }} className="fas fa-registered" />
              {itemdisc !== '0' ? (
                <>
                  <span style={{ textDecoration: 'line-through', marginRight:'10px', color:'red' }}>{itemprice}</span>  
                  <FontAwesomeIcon icon={faArrowRight} style={{marginRight:'10px'}}/>
                </>
              ) : null}
              {itemprice-((itemprice*itemdisc)/100)}
            </TableData>
            <TableData>
            {itemstock !== '0' ? (
                <LabelSuccessStyled><FontAwesomeIcon icon={faLayerGroup} style={{marginRight:'10px'}}/>{itemstock}</LabelSuccessStyled>
              
            ) : <LabelDangerStyled><FontAwesomeIcon icon={faLayerGroup} style={{marginRight:'10px'}}/>{itemstock}</LabelDangerStyled>}
            </TableData>
            <TableData> {itemdisc} %</TableData>
            <TableData>
              {hidden !== 1 ? (
              <LabelSuccessStyled>Active</LabelSuccessStyled>
              ) : <LabelDangerStyled>Hidden</LabelDangerStyled>}
            </TableData>
            <TableData>
              <UpdateButton onClick={() => navigate(`/shop/${productnum}`, { state: { rankItem } })}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </UpdateButton>
            </TableData> */}
      </TableRow>
    );
  };

  const formatDate = (dateString) => {
    // Create a Date object from the UTC date string
    const date = new Date(dateString);
  
    // Get the year, month, and day in UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    // Get the hours, minutes, and seconds in UTC
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Return in YYYY-MM-DD HH:MM:SS format
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

export default NewsPanelList;