import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Card = styled.div`
  border: none;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(145deg, #1e272e, #2f3640);
  width: 400px;
  height: 200px; /* Fixed height for the card */
  margin: 1rem;
  position: relative;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
  }
`;

const DateData = styled.div`
  background: transparent;
  color: #d1d8e0;
  padding: 1.5rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: right;
`;

const CardHeader = styled.div`
  background: #273c75;
  color: #f5f6fa;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds "..." when text overflows */
`;

const CardContent = styled.div`

  font-size: 1rem;
  color: #f5f6fa;
  overflow: hidden; /* Hide overflow if content is too long */
`;

const ReadMore = styled(Link)`
  padding: 20px;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #4cd137;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #44bd32;
    text-decoration: underline;
  }
`;

const AnnouncementCard = ({ id, date, title, content }) => (
  <Card>
    <CardHeader>{title}</CardHeader>
    <CardContent>
      <ReadMore
        to={{
          pathname: `/announcement/${id}`,
        }}
        state={{ id, date, title, content }}
      >
        Read More
      </ReadMore>
    </CardContent>
    <DateData>{formatDate(date)}</DateData>
  </Card>
);

const formatDate = (dateString) => {
  // Create a Date object from the UTC date string
  const date = new Date(dateString);

  // Get the year, month, and day in UTC
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');

  // Get the hours, minutes, and seconds in UTC
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return in YYYY-MM-DD HH:MM:SS format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


export default AnnouncementCard;
