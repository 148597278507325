import AnnouncementSection from '../components/AnnouncementSection';
import Sidebar from '../components/Sidebar';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import NewsPage from '../pages/NewsPage';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 2rem;
  position: relative;
  background: black;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 2rem;
  align-items: flex-start;
  z-index: 1;
`;

const AnnouncementContentWrapper = styled.div`
  position: abosulute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  // background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  margin-top: 32%;
  z-index: 5;
`;

const SidebarWrapper = styled.div`
  flex: 0 0 250px;
  
`;

const VideoWrapper = styled.div`
  position: absolute; /* Changed to relative positioning */
  width: 80%; /* Full width */
  height: 70vh; /* Adjusted height as needed */
  z-index: 0; /* Lower z-index to place behind navbar */
  background: rgba(0, 0, 0, 0.7); /* Optional background */
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    object-fit: cover;
    pointer-events: none; /* Prevent interaction */
  }
    margin-top: -95px;
    margin-left: -50px;
`;


const TitleWrapper = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #fff;
`;

const Home = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [error, setError] = useState(null);
  const videoId = 'EZsnt3P6USU'; // Replace with your actual video ID

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/news`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const top10News = Array.isArray(data.news) ? data.news.slice(0, 10) : [];
        setAnnouncements(top10News);
      } catch (error) {
        console.error('Error fetching news:', error);
        setError(error);
      }
    };

    if (announcements.length === 0) {
      fetchNews();
    }
  }, [announcements]);

  return (
    <>
      <HomeWrapper>
        <ContentWrapper>
          {/* YouTube video placed on top */}
          <VideoWrapper>
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1&playlist=${videoId}&vq=hd1080&controls=0`}
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="YouTube video background"
            />
          </VideoWrapper>
          <AnnouncementContentWrapper>
            <TitleWrapper>
              <Title>Announcements</Title>
            </TitleWrapper>
            <NewsPage />
          </AnnouncementContentWrapper>
        </ContentWrapper>

        {/* Sidebar */}
        <SidebarWrapper>
          <Sidebar />
        </SidebarWrapper>
      </HomeWrapper>
    </>
  );
};

export default Home;