// src/components/SocialMediaWidget.js
import React from 'react';
import styled from 'styled-components';

const WidgetContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically if needed */
  background: #171717;
  z-index: 1
`;

const WidgetTitle = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  color: white;
`;

const Iframe = styled.iframe`
  width: 100%;
  max-width: 300px; /* Set max-width to control iframe size */
  border: none;
  margin-bottom: 1rem;
`;

const SocialMediaWidget = () => (
  <WidgetContainer>
    <WidgetTitle>
      <h3>Social Media</h3>
    </WidgetTitle>
    <Iframe
      src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/OfficialRanGS&amp;tabs&amp;width=300&amp;height=100&amp;small_header=true&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
      height="100"
      scrolling="no"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />
    <Iframe
      src="https://discord.com/widget?id=1156515646264315905&amp;theme=dark"
      height="300"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
    />
  </WidgetContainer>
);

export default SocialMediaWidget;