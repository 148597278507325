// Function to get category name based on ID
export const getNewsCategoryName = (id) => {
  return id === 1 ? "Update"
       : id === 2 ? "Notice"
       : id === 3 ? "News"
       : id === 4 ? "Event"
       : null; // default name for unknown categories
};

export const getNewsCategoryColor = (category) => {
  return category === "Update" ? "#5cb85c"
       : category === "Notice" ? "#d9534f"
       : category === "News" ? "#337ab7"
       : category === "Event" ? "#f0ad4e"
       : null; // default color for unknown categories
};