import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { faTrash,faSpinner,faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
  LabelPrimaryStyled,
  LabelDangerStyled,
  LabelSuccessStyled,
} from '../utils/LabelUtils';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center align content now that there's only one section */
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1; /* Adjusted to fill available space */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 20px; /* Increase padding for larger buttons */
  margin: 0 8px; /* Adjust margin if needed */
  background-color: ${(props) => (props.active ? '#2c3e50' : '#bdc3c7')};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px; /* Increase font size if needed */

  &:hover {
    background-color: #2c3e50;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7f8c8d;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const LoadingSpinner = styled(FontAwesomeIcon)`
  font-size: 48px;
  color: #2c3e50;
  animation: spin 1s infinite linear;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #27ae60; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #2ecc71; /* Lighter green on hover */
  }
`;

const handleDelete = async(id,fetchDownloadList) => {

  const confirmation = window.confirm(
    `Do you want to delete download id : ${id}?`
  );

  if (confirmation) {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/download/delete?id=${id}`, {
          method: 'DELETE', // Specify the DELETE method
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
      });

      if (response.ok) {
          alert('Download link has been successfully removed.');
          fetchDownloadList();
      } else {
          const errorData = await response.json(); // Get the error response
          throw new Error(`Delete failed: ${errorData.error}`);
      }
    } catch (error) {
        console.error('Error deleting download:', error);
    }
  }
};

// Main component
const DownloadPanelList = () => {
  const [downloadList, setDownloadList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  const fetchDownloadList = async () => {
    setLoading(true); 
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/downloadlist`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setDownloadList(data.downloadlist);
    } catch (error) {
      console.error('Error fetching player ranking:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Simulate API call to fetch rankings
  useEffect(() => {
   
    fetchDownloadList();
  }, []);

   // Handle add item button click
   const handleAddItem = () => {
    navigate('/download/add'); // Navigate to add item page (change URL as needed)
  };

 

  return (
    <PageContainer>
      <ContentWrapper>
        {/* Ranking Table Section */}
        <RankingContainer>
          <Title>Download Panel - List</Title>
          <AddButton onClick={handleAddItem}>
              <FontAwesomeIcon icon={faPlusCircle} />
              Create Download Link
          </AddButton>
          {loading ? (
            <LoadingContainer>
              <LoadingSpinner icon={faSpinner} />
            </LoadingContainer>
          ) : ( <>
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>ID</TableHeader>
                <TableHeader>Info</TableHeader>
                <TableHeader>Server</TableHeader>
                <TableHeader>Name</TableHeader>
                <TableHeader>Ver</TableHeader>
                <TableHeader>Link</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Action</TableHeader>
              </tr>
            </thead>
            <tbody>
            {downloadList.map((rankItem) => (
              <TableComponent
                key={rankItem.id} // Ensure a unique key
                rankItem={rankItem} // Pass the entire rankItem object
                fetchDownloadList={fetchDownloadList}
              />
            ))}
            </tbody>
          </RankingTable>
          </>)}
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

const DeleteButton = styled(Button)`
  background-color: #c9302c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #c0392b;
  }
`;

const TableComponent = ({ rankItem,fetchDownloadList  }) => {
  const navigate = useNavigate();
    // Define your base path for images
    const campusBasePath = '../images/campus/'; // Adjust the path according to your folder structure
  
    // Generate the complete image path
    const imageCampusPath = `${campusBasePath}nocamp.png`;
   
    return (
        <TableRow>
            <TableData><LabelDangerStyled>{rankItem.id}</LabelDangerStyled></TableData>
            <TableData><LabelPrimaryStyled>{rankItem.category}</LabelPrimaryStyled></TableData>
            <TableData><img src={`../${rankItem.server}.png`} style={{height:'45px', width:'50px'}}></img></TableData>
            <TableData>{rankItem.name}</TableData>
            <TableData>{rankItem.version}</TableData>
            <TableData>{rankItem.link}</TableData>
            <TableData>
                {rankItem.status === 1 ? (
              <LabelSuccessStyled>Active</LabelSuccessStyled>
              ) : <LabelDangerStyled>Hidden</LabelDangerStyled>}
            </TableData>
            <TableData>
                <DeleteButton onClick={() => handleDelete(rankItem.id,fetchDownloadList)}>
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </DeleteButton>
            </TableData>
            
      </TableRow>
    );
  };

export default DownloadPanelList;
