import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { faTrash, faEdit,faPlusCircle,faShoppingBag,faUserShield,faUser  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../components/UserContext'; // Adjust the import as necessary
import {
  LabelPrimaryStyled,
  LabelDangerStyled,
  LabelSuccessStyled,
  LabelWarningStyled,
} from '../utils/LabelUtils';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center align content now that there's only one section */
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1; /* Adjusted to fill available space */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const RankingTable = styled.table`
  width: 70%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableHeaderMonthly = styled.th`
  background-color: #000;
  color: white;
  padding: 10px;
  text-align: left;
  width: 10%;
  border: 1px dashed #ccc;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 20px; /* Increase padding for larger buttons */
  margin: 0 8px; /* Adjust margin if needed */
  background-color: ${(props) => (props.active ? '#2c3e50' : '#bdc3c7')};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px; /* Increase font size if needed */

  &:hover {
    background-color: #2c3e50;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7f8c8d;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

const Table1 = styled.table`
  padding: 15px;
  border-collapse: collapse;
  margin: 20px 20px; /* Adjust the margins here */
  width: 40%; /* Adjust width for two tables */
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Table shadow */
  transition: all 0.3s ease;
`;

const TableRow1 = styled.tr`

  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Row hover effect */
    cursor: pointer;
  }
`;

const TableHeaderGate = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
  width: 10%;
`;

const TableHeaderAction = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
  width: 10%;
`;

const TableData1 = styled.td`
  padding: 15px;
  
  border: 1px dashed #ddd;
  color: white;
  text-align: left;
  font-size: 16px;
  &:first-child {
    font-weight: bold;
    color: white;
    background-color: #2c3e50;
    width: 30%; /* Set a fixed width for the first column */
  }
`;

const TableDataEdit = styled.td`
  padding: 15px;
  
  border: 1px dashed #ddd;
  color: white;
  text-align: left;
  font-size: 16px;
  width: 10%;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #27ae60; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #2ecc71; /* Lighter green on hover */
  }
`;


const RechargeLogButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px; /* Add some space to the right */
  background-color: #c9302c; /* Green for 'Add Item' */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */

  &:hover {
    background-color: #e74c3c; /* Lighter green on hover */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 20px; /* Add spacing between buttons */
  margin-bottom: 20px;
`;



// Main component
const SuperAdminPanelList = () => {
  const [adminList, setAdminList] = useState([]);
  const [paypalList, setPaypalList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [countTicketList, setCountTicketList] = useState([]);
  const [countEmailList, setCountEmailList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [kbStatus, setKBStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [showModal, setShowModal] = useState(false); 
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(UserContext);


  const fetchAdminList = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/adminlist`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAdminList(data.adminlist);
    } catch (error) {
        console.error('Error fetching player ranking:', error);
    }
    };

    const fetchCountTicketList = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/countticketlist`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
            },
          });
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setCountTicketList(data.countticketlist);
      } catch (error) {
          console.error('Error fetching player ranking:', error);
      }
    };

    const fetchCountEmailList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/countemaillist`, {
              headers: {
                'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
              },
            });
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCountEmailList(data.countemaillist);
        } catch (error) {
            console.error('Error fetching player ranking:', error);
        }
      };
    

    useEffect(() => {
        fetchAdminList();
        fetchCountTicketList();
        fetchCountEmailList();
    }, []);

    // Handle add item button click
   const handleAddAdmin = () => {
    navigate('/admin/add'); // Navigate to add item page (change URL as needed)
   };

   // Handle add item button click
  const handleAddCountTicket = () => {
    navigate('/admin/add/ticket'); // Navigate to add item page (change URL as needed)
  };

  // Handle add item button click
  const handleAdminLog = () => {
    navigate('/admin/log'); // Navigate to add item page (change URL as needed)
  };

  // Handle add item button click
  const handleUserLog = () => {
    navigate('/admin/user/log'); // Navigate to add item page (change URL as needed)
  };

  const findStatus = (gateway) => {
      const status = kbStatus.find(item => item.gateway === gateway);
      if (status) {
          return status.status === '1' ? <LabelSuccessStyled>Online</LabelSuccessStyled> : <LabelDangerStyled>Offline</LabelDangerStyled>;  // Check if status is 1 or 0
      }
      return 'No Data';  // Default case when no matching gateway is found
  };

  const findDetail = (gateway) => {
      const status = kbStatus.find(item => item.gateway === gateway);
      if (status) {
          return <><LabelPrimaryStyled>{status.status}</LabelPrimaryStyled> <LabelWarningStyled>{status.detail}</LabelWarningStyled></>
      }
      return 'No Data';  // Default case when no matching gateway is found
  };

  const handleEdit = async (selectedGateway) => {
   
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
      <PageContainer>
    <ContentWrapper>
      {/* Ranking Table Section */}
      <RankingContainer>
      <Title>Admin Panel - List</Title>
      <ButtonContainer>
      <AddButton onClick={handleAddAdmin}>
            <FontAwesomeIcon icon={faPlusCircle} />
            Create Admin
      </AddButton>

      <AddButton onClick={handleAddCountTicket}>
            <FontAwesomeIcon icon={faPlusCircle} />
            Admin Email Panel / Ticket Panel
      </AddButton>
      <p></p>

      <RechargeLogButton onClick={handleAdminLog}>
            <FontAwesomeIcon icon={faUserShield} />
            Admin Log
        </RechargeLogButton>

        <RechargeLogButton onClick={handleUserLog}>
            <FontAwesomeIcon icon={faUser} />
            User Log
        </RechargeLogButton>

        </ButtonContainer>
      <center>
      
      <tr style={{ height: '20px' }} /> {/* Adds space between rows */}
          
      {renderAdminTableRows(adminList,userData,navigate,setAdminList)}
      <tr style={{ height: '50px' }} /> {/* Adds space between rows */}
      <Title>Email / Pin Panel List</Title>
      {renderCountEmailTableRows(countEmailList,userData,navigate,setCountEmailList)}

      <tr style={{ height: '50px' }} /> {/* Adds space between rows */}
      <Title>Ticket Panel List</Title>
      {renderCountTicketTableRows(countTicketList,userData,navigate,setCountTicketList)}

        </center>
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

const renderAdminTableRows = (pesoList,userData,navigate,setAdminList) => {
  
    return <RankingTable>
    <thead>
        <tr>
        <TableHeaderGate>No</TableHeaderGate>
        <TableHeader>Name</TableHeader>
        <TableHeader>Status</TableHeader>
        <TableHeaderAction>Action</TableHeaderAction>
        </tr>
    </thead>
    <tbody>
    {pesoList.map((rankItem) => (
        <TableAdminComponent
        key={rankItem.usernum} // Ensure a unique key
        rankItem={rankItem} // Pass the entire rankItem object
        userData={userData}
        navigate={navigate}
        setAdminList={setAdminList}
        />
    ))}
    </tbody>
    </RankingTable>
  };

  const renderCountEmailTableRows = (pesoList,userData,navigate,setCountEmailList) => {
    return <RankingTable>
    <thead>
        <tr>
        <TableHeaderGate>No</TableHeaderGate>
        <TableHeader>Name</TableHeader>
        <TableHeaderAction>Action</TableHeaderAction>
        </tr>
    </thead>
    <tbody>
    {pesoList.map((rankItem) => (
        <TableCountEmailComponent
        key={rankItem.usernum} // Ensure a unique key
        rankItem={rankItem} // Pass the entire rankItem object
        userData={userData}
        navigate={navigate}
        setCountEmailList={setCountEmailList}
        />
    ))}
    </tbody>
    </RankingTable>
  };

  const renderCountTicketTableRows = (pesoList,userData,navigate,setCountTicketList) => {
    return <RankingTable>
    <thead>
        <tr>
        <TableHeaderGate>No</TableHeaderGate>
        <TableHeader>Name</TableHeader>
        <TableHeader>Alias</TableHeader>
        <TableHeader>Reply Count</TableHeader>
        <TableHeaderAction>Action</TableHeaderAction>
        </tr>
    </thead>
    <tbody>
    {pesoList.map((rankItem) => (
        <TableCountTicketComponent
        key={rankItem.usernum} // Ensure a unique key
        rankItem={rankItem} // Pass the entire rankItem object
        userData={userData}
        navigate={navigate}
        setCountTicketList={setCountTicketList}
        />
    ))}
    </tbody>
    </RankingTable>
  };

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

const UpdateButton = styled(Button)`
  background-color: #c9302c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #c0392b;
  }
`;

const TableAdminComponent = ({ rankItem,userData,navigate,setAdminList }) => {
  
    return (
        <TableRow>
            <TableData>{rankItem.usernum}</TableData>
            <TableData>{rankItem.username}</TableData>
            <TableData><LabelDangerStyled>
                {rankItem.usertype === 30 ? 'Admin' : 
                rankItem.usertype === 20 ? 'GM' : 
                rankItem.usertype}
                </LabelDangerStyled>
            
            {rankItem.superadmin === 1 &&
                <LabelWarningStyled>Super Admin</LabelWarningStyled>
            }
            </TableData>

            <TableData>
            <UpdateButton onClick={() => handleAdminDelete(rankItem, userData,navigate,setAdminList)}>
              <FontAwesomeIcon icon={faEdit} />
              Edit
            </UpdateButton>
          </TableData>
      </TableRow>
    );
  };

  const handleAdminDelete = async (rankItem,userData,navigate,setAdminList) => {

    const confirmation = window.confirm(
      `Do you wish to take ${rankItem.username} off the admin list`
    );
    
    if (confirmation) {
      const newItem = {
        username: rankItem.username,
        type:  1,
        adminname: userData.username,
      };
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/admin/delete`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
          body: JSON.stringify(newItem),
        });

        if (!response.ok) {
          throw new Error('Failed to add admin');
        }

        setAdminList(prevAdminList => 
          prevAdminList.filter(admin => admin.username !== rankItem.username)
        );
        alert(`${rankItem.username} removed as admin`)
        navigate('/admin/panel'); // Redirect to the download list page
      } catch (error) {
        console.error('Error adding Admin:', error);
      }
    }
  };

  const handleCountTicketDelete = async (rankItem,userData,navigate,setCountTicketList) => {

    const confirmation = window.confirm(
      `Do you wish to take ${rankItem.username} off the ticket list`
    );
    
    if (confirmation) {
      const newItem = {
        username: rankItem.username,
        userticket: 0,
        adminname: userData.username,
      };
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/adminticket/delete`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
          body: JSON.stringify(newItem),
        });

        if (!response.ok) {
          throw new Error('Failed to add admin');
        }

        setCountTicketList(prevAdminList => 
          prevAdminList.filter(admin => admin.username !== rankItem.username)
        );
        alert(`${rankItem.username} removed as ticket admin`)
        navigate('/admin/panel'); // Redirect to the download list page
      } catch (error) {
        console.error('Error adding Admin:', error);
      }
    }
  };

  const handleCountEmailDelete = async (rankItem,userData,navigate,setCountEmailList) => {

    const confirmation = window.confirm(
      `Do you wish to take ${rankItem.username} off the email/pin list`
    );
    
    if (confirmation) {
      const newItem = {
        username: rankItem.username,
        trustedid:  0,
        adminname: userData.username,
      };
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/adminemail/delete`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
          body: JSON.stringify(newItem),
        });

        if (!response.ok) {
          throw new Error('Failed to add admin');
        }

        setCountEmailList(prevAdminList => 
          prevAdminList.filter(admin => admin.username !== rankItem.username)
        );
        alert(`${rankItem.username} removed as email admin`)
        navigate('/admin/panel'); // Redirect to the download list page
      } catch (error) {
        console.error('Error adding Admin:', error);
      }
    }
  };

  const TableCountTicketComponent = ({  rankItem,userData,navigate,setCountTicketList  }) => {
  
    return (
        <TableRow>
            <TableData>{rankItem.rownum}</TableData>
            <TableData>{rankItem.username}</TableData>
            <TableData>{rankItem.ticketalias}</TableData>
            <TableData>{rankItem.ticketcount}</TableData>
            <TableData>
              <UpdateButton onClick={() => handleCountTicketDelete(rankItem, userData,navigate,setCountTicketList)}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </UpdateButton>
            </TableData>
      </TableRow>
    );
  };

  const TableCountEmailComponent = ({  rankItem,userData,navigate,setCountEmailList  }) => {
  
    return (
        <TableRow>
            <TableData>{rankItem.rownum}</TableData>
            <TableData>{rankItem.username}</TableData>
            <TableData>
            <UpdateButton onClick={() => handleCountEmailDelete(rankItem, userData,navigate,setCountEmailList)}>
              <FontAwesomeIcon icon={faEdit} />
              Edit
            </UpdateButton>
            </TableData>
      </TableRow>
    );
  };

export default SuperAdminPanelList;