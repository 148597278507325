// utils/LabelUtils.js
import styled from 'styled-components';

// Primary Label
export const LabelPrimary = styled.span`
  background-color: #337ab7;   /* Primary background color */
  color: white;                /* Text color */
  padding: 5px 10px;           /* Padding inside the label */
  border-radius: 4px;          /* Rounded corners */
  font-size: 14px;             /* Font size */
`;

// Danger Label
export const LabelDanger = styled.span`
  background-color: #d9534f;   /* Danger background color */
  color: white;                /* Text color */
  padding: 5px 10px;           /* Padding inside the label */
  border-radius: 4px;          /* Rounded corners */
  font-size: 14px;             /* Font size */
`;

// Success Label
export const LabelSuccess = styled.span`
  background-color: #5cb85c;   /* Success background color */
  color: white;                /* Text color */
  padding: 5px 10px;           /* Padding inside the label */
  border-radius: 4px;          /* Rounded corners */
  font-size: 14px;             /* Font size */
`;

// Success Label
export const LabelWarning = styled.span`
  background-color: #f0ad4e;   /* Success background color */
  color: white;                /* Text color */
  padding: 5px 10px;           /* Padding inside the label */
  border-radius: 4px;          /* Rounded corners */
  font-size: 14px;             /* Font size */
`;

// Additional styling for Primary Label in a specific container
export const LabelPrimaryStyled = styled(LabelPrimary)`
  margin-right: 5px; /* Add space between itemmain and itemsub */
`;

// Additional styling for Danger Label in a specific container
export const LabelDangerStyled = styled(LabelDanger)`
  margin-left: 5px;
`;

// Additional styling for Success Label in a specific container
export const LabelSuccessStyled = styled(LabelSuccess)`
  margin-left: 5px;
`;

// Additional styling for Success Label in a specific container
export const LabelWarningStyled = styled(LabelWarning)`
  margin-left: 5px;
`;

