import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import { UserContext } from '../components/UserContext'; // Import the UserContext
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const FormContainer = styled.div`
  width: 40%;
  background-color: #1c1c1c;
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 5px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-top: 100px;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SuccessMessage = styled.div`
  font-family: "Roboto", sans-serif;
  background-color: #2e7d32;
  padding: 15px;
  color: white;
  text-align: center;
`;

const FormField = styled.input`
  width: 95%;
  margin: 0;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  background: #333;
  color: #eee;

  &::placeholder {
    color: grey;
  }

  &.error {
    border-color: #ff6f61;
  }
`;

const ErrorSpan = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ff6f61;
  margin: 5px 0;
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  background: #273c75;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;

  &:disabled {
    cursor: not-allowed;
    background: #004d40;
  }
`;

const FormFieldContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const BackButton = styled.button`
  
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: #888;
  }
`;

const UpdateEmail = React.memo(() => {
  const [values, setValues] = useState({
    pincode: "",
    email: "",
  });

  const { userData, setUserData } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [serverError, setServerError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(values);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/updateuseremail`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({
            userId: userData?.username,
            pincode: values.pincode,
            email: values.email,
          }),
        });
        if (response.ok) {
          setUserData({ ...userData, email: values.email });
          localStorage.setItem('userData', JSON.stringify({ ...userData, email: values.email }));
          setSubmitted(true);
          setSuccessMessage("Your email has been updated successfully!");
          setServerError("");
          setTimeout(() => {
            navigate("/profile"); // Adjust path as needed
          }, 2000);
        } else {
          const data = await response.json();
          setServerError(data.error || "Update failed");
          setSuccessMessage("");
        }
      } catch (err) {
        console.error("Error updating user:", err);
        setServerError("Update failed");
        setSuccessMessage("");
      }
    }
    setErrors(validationErrors);
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.pincode) errors.pincode = "Please enter a pincode";
    if (!values.email) errors.email = "Please enter your email";
    else if (!/\S+@\S+\.\S+/.test(values.email)) errors.email = "Invalid email format";

    return errors;
  };

  return (
    <FormContainer>
         <BackButton onClick={() => navigate(-1)}> {/* Navigates back to the previous page */}
    <FontAwesomeIcon icon={faArrowLeft} />    Back
      </BackButton>
      <RegisterForm onSubmit={handleSubmit}>
        {successMessage && (
          <SuccessMessage>{successMessage}</SuccessMessage>
        )}
        <FormFieldContainer>
          <FormField
            type="text"
            placeholder="Pincode"
            name="pincode"
            value={values.pincode}
            onChange={handleInputChange}
            className={errors.pincode ? "error" : ""}
          />
          {errors.pincode && (
            <ErrorSpan>{errors.pincode}</ErrorSpan>
          )}
        </FormFieldContainer>
        <FormFieldContainer>
          <FormField
            type="email"
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            className={errors.email ? "error" : ""}
          />
          {errors.email && (
            <ErrorSpan>{errors.email}</ErrorSpan>
          )}
        </FormFieldContainer>
        {serverError && (
          <ErrorSpan>{serverError}</ErrorSpan>
        )}
        <SubmitButton type="submit">
          Update Email
        </SubmitButton>
      </RegisterForm>
    </FormContainer>
  );
});

export default UpdateEmail;
