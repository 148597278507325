// src/components/RechargeCart.js
import React, { useState } from 'react';
import styled from 'styled-components';
import CartTable from '../components/CartTable';
import CheckoutForm from '../components/CheckoutForm';
import { useLocation, useNavigate } from 'react-router-dom';


// const Wrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   height: 100vh; // Full viewport height
//   margin: 0;
// `;

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 20px; // Adjust padding as needed
// `;

const Wrapper = styled.div`
  text-align: -webkit-center;
`;

const RechargeCart = () => {
  const location = useLocation(); // Get the location object
  const { id, kbpoint, price, logo, currencySign } = location.state || {};  
  return (
    <Wrapper>
        <CartTable id={id} kbpoint={kbpoint} price={price} logo={logo} currencySign={currencySign} />
        <CheckoutForm id={id} kbpoint={kbpoint} price={price} logo={logo} currencySign={currencySign}/>
    </Wrapper>
  ); 
};



export default RechargeCart;