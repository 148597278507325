import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const GameDetailsWrapper = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: auto;
`;

const GameTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const GameImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const GameDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
`;

const GameDetails = () => {
  const { id } = useParams();
  const [game, setGame] = useState(null);

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const response = await axios.get(`/api/games/${id}`);
        setGame(response.data);
      } catch (error) {
        console.error('Error fetching game details:', error);
      }
    };

    fetchGame();
  }, [id]);

  if (!game) return <p>Loading...</p>;

  return (
    <GameDetailsWrapper>
      <GameTitle>{game.title}</GameTitle>
      <GameImage src={game.image} alt={game.title} />
      <GameDescription>{game.description}</GameDescription>
    </GameDetailsWrapper>
  );
};

export default GameDetails;
